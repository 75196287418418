import {
  maxTablet,
  maxWidth,
  primaryColor, primaryFont, retina, secondaryFont, wMedium,
} from 'Utils/utilsStyle';
import { Divider } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import CrossCircle from 'Components/common/assets/svgIcons/CrossCircle';
import { useSelector } from 'react-redux';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import { formatNumberWithCommas } from 'Utils/UtilityFunctions';

const ContentWrapper = styled.div`
  &.withCount {
    align-items: baseline;
    .ant-divider {
      top: 0.3em;
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .tab-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${secondaryFont};
    font-size: 14px;
    font-weight: ${wMedium};
    line-height: 24px;
    letter-spacing: -0.272px;
    color:  #4E565B;
    cursor: pointer;
    @media all and (max-width: ${maxTablet}) {
      font-size: 12px;
    }
    &.active {
      color: ${primaryColor};
      .count {
        color: ${primaryColor};
      }
    }
    &.count {
      font-size: 12px;
      font-family: ${primaryFont};
      @media all and (max-width: ${maxTablet}) {
        font-size: 10px;
      }
    }
  }
  .ant-divider {
    height: 15px;
    border-color:#979797;
  }
  .cross-circle-svg {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    @media all and (max-width: ${maxWidth}), ${retina} {
      width: 20px;
      height: 20px;
    }
  }
`;

type Props = {
  active: number,
  peopleCount: number,
  companiesCount: number
  buyerCount: number
  docCount: number
  setActive: (a: number) => void
  onCrossClick: () => void
};

const PopoverTabs = function PopoverTabs(props: Props) {
  const {
    active, peopleCount, companiesCount, buyerCount, setActive, onCrossClick,
    docCount,
  } = props;
  const disclessPersonalitys = useSelector(selectDisclessPersonality);
  const formattedPeopleCount = formatNumberWithCommas(peopleCount);
  const formattedCompaniesCount = formatNumberWithCommas(companiesCount);
  const formattedBuyerCount = formatNumberWithCommas(buyerCount);
  const formattedDocCount = formatNumberWithCommas(docCount);

  return (
    <ContentWrapper className={classNames({ withCount: peopleCount })}>
      <span className={classNames('tab-title', { active: active === 1 })} onClick={() => { setActive(1); }} role="button" onKeyPress={() => { setActive(1); }} tabIndex={0}>
        People
        <span className="tab-title count">
          (
          {formattedPeopleCount}
          )
        </span>
      </span>
      <Divider type="vertical" />
      <span className={classNames('tab-title', { active: active === 2 })} onClick={() => { setActive(2); }} role="button" onKeyPress={() => { setActive(2); }} tabIndex={0}>
        Companies
        <span className="tab-title count">
          (
          {formattedCompaniesCount}
          )
        </span>
      </span>
      <Divider type="vertical" />
      <span className={classNames('tab-title', { active: active === 3 })} onClick={() => { setActive(3); }} role="button" onKeyPress={() => { setActive(3); }} tabIndex={0}>
        BRM
        <span className="tab-title count">
          (
          {formattedBuyerCount}
          )
        </span>
      </span>
      {
        disclessPersonalitys ? null : (
          <>
            <Divider type="vertical" />
            <span className={classNames('tab-title', { active: active === 3 })} onClick={() => { setActive(3); }} role="button" onKeyPress={() => { setActive(3); }} tabIndex={0}>
              BRM
              <span className="tab-title count">
                (
                {formattedBuyerCount}
                )
              </span>
            </span>
          </>
        )
      }
      {docCount ? (
        <>
          <Divider type="vertical" />
          <span className={classNames('tab-title', { active: active === 4 })} onClick={() => { setActive(4); }} role="button" onKeyPress={() => { setActive(4); }} tabIndex={0}>
            Doc Hub
            <span className="tab-title count">
              (
              {formattedDocCount}
              )
            </span>
          </span>
        </>
      ) : null}
      <CrossCircle onClick={onCrossClick} />
    </ContentWrapper>
  );
};

export default PopoverTabs;
