/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'Store/InjectorsTypes';
import { initialState } from '../Slice/UISlice';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state?.ui || initialState;

export const selectShowAlertsSetting = createSelector([selectDomain], (state) => state.isTrue);
export const selectStickyTabsShow = createSelector([selectDomain], (state) => state.isStickyState);
export const spotlightInView = createSelector([selectDomain], (state) => state.isSpotLightInView);
export const globalSearchOpen = createSelector([selectDomain], (state) => state.isSearchOpen);
export const selectGroupTitleName = createSelector([selectDomain], (state) => state.groupTitleName);
export const selectIsPersonDrag = createSelector([selectDomain], (state) => state.isPersonDrag);
export const selectViewTab = createSelector([selectDomain], (state) => state.tabView);
export const isDrawerOpen = createSelector([selectDomain], (state) => state.isDrawerOpen);
export const isExpandFlag = createSelector([selectDomain], (state) => state.expandFlag);
export const setOnBoardingSlide = createSelector([selectDomain], (state) => state.onBoardingSlide);
export const isSimilarArticleScroll = createSelector(
  [selectDomain],
  (state) => state.isSimilarArticleScroll,
);
export const isSimilarArticleScrollItem = createSelector(
  [selectDomain],
  (state) => state.isSimilarArticleScrollItem,
);
export const isDrawerPanelOpen = createSelector([selectDomain], (state) => state.isDrawerPanelOpen);
export const isGilroyTextHeight = createSelector(
  [selectDomain],
  (state) => state.isGilroyTextHeight,
);
export const isShowGilroyGlobal = createSelector(
  [selectDomain],
  (state) => state.isShowGilroyGlobal,
);
export const isEditChatOpen = createSelector(
  [selectDomain],
  (state) => state.isEditChatOpen,
);
export const isShowAskGif = createSelector(
  [selectDomain],
  (state) => state.isShowAskGif,
);

export const selectGroupCategory = createSelector(
  [selectDomain],
  (state) => state.groupCategory,
);
