/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'Utils/Axios';
import { API } from 'Utils/Constants';

import { actions } from 'Slice/SettingsSlice';

import { SendVerification, SettingsRequest } from 'Types/RequestBodies';
import { SettingResponse } from 'Types/SettingTypes';
import {
  getCookie, getCurrentTime, setCookie, setLocalStorageValue, valueToObject,
} from 'Utils/UtilityFunctions';
import { actions as helperActions } from 'Slice/HelperSlice';
import { actions as globalAction } from '../Slice/GlobalSearchContentSlice';

export function* updateSetting(dispatched: PayloadAction<SettingsRequest>) {
  const startTime = getCurrentTime();
  try {
    const response: SettingResponse = yield call(
      axios.post,
      API.UPDATE_PERSON_INFO,
      dispatched?.payload,
    );
    if (response?.status === true) {
      const { payload: { fullname, phone, toggle } } = dispatched;
      if (fullname?.length && !toggle) {
        setLocalStorageValue('fullname', fullname);
      }
      yield put(globalAction.updateUserContact(phone || ''));
      const { response: { jwt } } = response;
      if (jwt) {
        setLocalStorageValue('access_id', jwt);
      }
      const { response: { avatarImage } } = response;
      if (avatarImage?.length) {
        setLocalStorageValue('profilePic', avatarImage);
        if (getCookie('image')) {
          setCookie('image', avatarImage);
        }
      }
      yield put(actions.updateSettingSuccess());

      yield put(helperActions.callFlurryEvent(
        valueToObject('user_info_edit_finish', startTime, dispatched?.payload),
      ));
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    } else {
      yield put(actions.updateSettingfail());
      yield put(helperActions.setNotification({
        message: response?.message,
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: false,
      }));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* sendVerification(dispatched: PayloadAction<SendVerification>) {
  try {
    const response: SettingResponse = yield call(
      axios.post,
      API.API_VERIFICATION,
      dispatched?.payload,
    );
    if (response?.status) {
      yield put(actions.setSendVerification());
    }
  } catch (err) {
    console.log(err);
  }
}

export function* verifyCode(dispatched: PayloadAction<SendVerification>) {
  try {
    const response: SettingResponse = yield call(
      axios.post,
      API.API_USER_VERIFY,
      dispatched?.payload,
    );
    if (response?.status) {
      yield put(actions.setVerificationCodeResponse(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* SettingsSaga() {
  yield takeLatest(actions.updateSetting.type, updateSetting);
  yield takeLatest(actions.sendVerification.type, sendVerification);
  yield takeLatest(actions.verifyCode.type, verifyCode);
}
