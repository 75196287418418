/* eslint-disable no-nested-ternary */
import React, { lazy, Suspense, useState } from 'react';
import styled from 'styled-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { COMPANY_OVERVIEW_ROUTE, PERSONALITY_INSIGHTS_ROUTE } from 'Utils/Constants';
import { isXiqUser } from 'Utils/UtilityFunctions';
import { useSelector } from 'react-redux';
import { selectDisclessPersonality } from 'Selector/GlobalSearchContentSelector';
import DrawerPeopleMoveReason from 'Components/people/components/DrawerPeopleMoveReason';
import UserQuery from 'Components/chatBotV2/mainComponents/chatbotHistory/components/UserQuery';
import {
  secondaryFont, wMBold, maxWidth, retina,
  primaryFont, wMedium, primaryHeadingColor, wELight, minWidthApp, maxMobile,
  smMobile,
  nutenixGradientColor,
  minExtraLargeScreen,
  maxTablet,
  noPersonImage,
} from '../../../Utils/utilsStyle';

const DiscIconSmall = lazy(() => import('../assets/svgIcons/DiscIconSmall'));
const RemoveIcon = lazy(() => import('../assets/svgIcons/RemoveIcon'));

export type WidgetProps = {
  showDrawerIcon?: boolean,
  persona?: string,
  logo: string,
  ringPadding?: string,
  hidePersonality?: boolean,
  personality?: string,
  diameter?: string,
  diameterSm?: string,
  diameterXsm?: string
  margin?: string,
  marginSm?: string,
  imageClassName?: string
  discImage?: boolean;
  wrapperColor?: string;
  showPersonName?: boolean;
  personName?: string;
  showCheckBox?: boolean;
  showRemoveIcon?: boolean;
  addRemovePerson?: (a: boolean, b: number) => void
  personId?: number,
  checked?: boolean,
  type?: string,
  companyId?: number,
  openInNewTab?: boolean,
  disableNavigation?: boolean,
  ringPosition?: string,
  ringSize?: string,
  ringPaddingSm?: string,
  personForHover?: boolean;
  personDesignation?: string,
  topPersonHover?: boolean,
  classNameWrapper?: string
  inGlobalSearch?: boolean,
  sapPersonAppear?: boolean,
  topPeopleNutanix?: boolean,
  industryWidget?: boolean,
} & typeof defaultProps;
const defaultProps = {
  showDrawerIcon: false,
  ringSize: 'cover',
  ringPosition: 'center',
  personId: 0,
  hidePersonality: true,
  showPersonName: false,
  personality: 'Conscientious',
  ringPadding: '4px',
  diameter: '160px',
  diameterSm: '90px',
  margin: '0px 3px 25px 3px',
  marginSm: '0px 0px 0px 0px',
  diameterXsm: '29px',
  imageClassName: '',
  discImage: false,
  checked: false,
  wrapperColor: '#979797',
  persona: '#979797',
  personName: 'Adam',
  showCheckBox: false,
  showRemoveIcon: false,
  type: 'EXECUTIVE',
  companyId: 0,
  ringPaddingSm: '4px',
  addRemovePerson: () => { },
  openInNewTab: false,
  disableNavigation: false,
  personForHover: false,
  topPersonHover: false,
  personDesignation: '',
  classNameWrapper: '',
  inGlobalSearch: false,
  sapPersonAppear: false,
  topPeopleNutanix: false,
  industryWidget: false,
};
const Wrapper = styled.div<{
  ringPaddingSm: string, color: string, diameter: string, diameterSm: string,
  margin: string, marginSm: string, ringPadding: string,
  diameterXsm: string,
}>`
&.searchWidget {
  width: 35px;
  height: 35px;
  min-width: 35px;
  @media all and (min-width: ${minExtraLargeScreen}) {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
  @media all and (max-width: ${maxWidth}) , ${retina} {
    width: 27px;
    height: 27px;
    min-width: 27px;
    padding: ${(props) => props.ringPaddingSm} !important;
    margin: ${(props) => props.marginSm};
  }
  @media all and (max-width: ${minWidthApp}){
    width: 25px;
    height: 25px;
    min-width:25px;
  }
  @media all and (max-width: ${smMobile}) {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}
 width: ${(props) => props.diameter};
 height: ${(props) => props.diameter};
 min-width: ${(props) => props.diameter};
 padding: ${(props) => props.ringPadding} !important;
 border-radius: 50%;
 background: conic-gradient(from -180deg, ${(props) => props.color});
 position:relative;
 margin: ${(props) => props.margin};
  @media all and (max-width: ${maxWidth}) , ${retina} {
    width: ${(props) => props.diameterSm};
    height: ${(props) => props.diameterSm};
    min-width: ${(props) => props.diameterSm};
    padding: ${(props) => props.ringPaddingSm} !important;
    margin: ${(props) => props.marginSm};
  }

  @media all and (max-width: ${minWidthApp}){
    width: ${(props) => props.diameterXsm};
    height: ${(props) => props.diameterXsm};
    min-width:${(props) => props.diameterXsm};

  }
  &.showSmallInXs {
    @media all and (max-width: ${maxMobile}) {
      width: ${(props) => props.diameterSm};
      height: ${(props) => props.diameterSm};
      min-width: ${(props) => props.diameterSm};
    }
  }
  .ant-checkbox {
    top: 0px;
    right: 10px;
    position: absolute;
    .ant-checkbox-inner {
     width: 25px;
     height: 25px;
     @media all and (max-width: ${maxWidth}) , ${retina} {
      width: 20px;
      height: 20px;
     }
     &:after {
       left: 30.5%;
     }
    }
  }
  .remove-btn {
    cursor: pointer;
    top: 0px;
    right: 10px;
    position: absolute;
    svg {
      width: 25px;
    }
  }
  &:hover {
   .personForHoverActive, .span-heading  {
     visibility: visible;
   } 
  }
  .personForHoverActive {
    font-size: 14px;
    font-family: ${primaryFont};
    font-weight: ${wMedium};
    color: #7E7E7E;
    text-align: center;
    line-height: 26px;
    position: absolute;
    left: 50%;
    bottom: -30px;
    margin-bottom: 6px;
    transform: translateX(-50%);
    width: max-content;
    visibility: hidden;
    @media all and (max-width: ${minWidthApp}) {
     display: none !important;
    }
  }
  .span-heading {
  text-align: center;
  color: ${primaryHeadingColor};
  font-weight:${wMedium};
  font-family:${primaryFont};
  font-size:14px;
  margin:0;
  transform: translateX(-50%);
  width: max-content;
  visibility: hidden;
  position: absolute;
  left: 50%;
  bottom: -47px;
  margin-bottom: 2px;
   span{
    font-size: 12px;
    font-weight:${wELight};
    font-family:${primaryFont};
    display: block;
  }
  @media all and (max-width: ${minWidthApp}) {
    display: none;
  }
 }
  .span-heading {
    &.topSpotLightPersonHover{
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -25px !important;
      .person-designation {
        margin-left: 4px !important;
      }
      @media all and (max-width: ${minWidthApp}) {
        display: none;
      }
    }
  }
`;
const Image = styled.div<{ image: string, ringSize: string, ringPosition: string, }>`
 width: 100%;
 height: 100%;
 border-radius: 50%;
 background-image: url(${(props) => props.image});
 background-color: #fff;
 background-repeat: no-repeat;
 background-position: ${(props) => props.ringPosition};
 background-size: ${(props) => props.ringSize};
 /* border: 5px solid #f3f4f4; */
`;
const PersonalityWrapper = styled.div<{ color: string, }>`
 min-width: 100px;
 width: fit-content;
 background: linear-gradient(to right, ${(props) => props.color}, ${(props) => props.color});
 /* background: linear-gradient(to right, ${(props) => props.color}); */
 /* background-color: ${(props) => props.color}; */
 position: absolute;
 bottom: -37px;
 left: 50%;
 transform: translate(-50%, -50%);
 border-radius: 30px;
  @media all and (max-width: ${maxWidth}) , ${retina} {
    min-width: 90px;
    bottom:-24px;
  }
  .anticon {
    min-width: 17px;
  }
 h4 {
    font-size: 14px;
    font-family: ${secondaryFont};
    letter-spacing: -0.35;
    color: #fff;
    margin: 5px;
    padding-left: 10px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      font-size: 10px;
      margin: 3px;
    }
 }
 &:hover {
   cursor: pointer;
   /* transform: translate(-50%, -50%) scale(1.1); */
   /* transition: 0.05s all;    */
 }
 .disc-img {
    position: absolute;
    bottom: -5px;
    left: -50px;
    @media all and (max-width: ${maxWidth}) , ${retina} {
      left: -40px;
    }
    & svg {
      height: 28px;
      border-radius: 50%;
      @media all and (max-width: ${maxWidth}) , ${retina} {
        height: 16px;
      }
    }
  }
`;

const PersonName = styled.span`
 font-family: ${secondaryFont};
 font-size: 14px;
 font-weight: ${wMBold};
 display: inline-block;
 width: 100%;
 text-align: center;
 margin-top: 8px;
`;

const PeopleWidgetWithOutHolo = function PeopleWidgetWithOutHolo(props: WidgetProps) {
  const navigate = useNavigate();
  const {
    persona, logo, personality, diameter, diameterSm,
    hidePersonality, margin, marginSm, imageClassName, discImage,
    wrapperColor, showPersonName, personName, showCheckBox,
    showRemoveIcon, personId, addRemovePerson, type, checked,
    disableNavigation, personForHover,
    companyId, openInNewTab, ringPadding, topPersonHover,
    ringPosition, ringSize, ringPaddingSm, personDesignation,
    diameterXsm, classNameWrapper, sapPersonAppear, inGlobalSearch, showDrawerIcon, industryWidget,
    topPeopleNutanix, // for nutanix Profile Change
  } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const color = companyId || sapPersonAppear
    ? wrapperColor : disclessPersonality ? nutenixGradientColor : wrapperColor;
  const selection = () => {
    addRemovePerson(!checked, personId);
  };
  const navigateTo = () => {
    if (!disableNavigation) {
      let url = '';
      if (personId) url = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${type}`;
      else if (companyId) url = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
      if (url?.length) {
        if (openInNewTab) window.open(url);
        else navigate(url);
      }
    }
  };
  const items = [
    {
      label:
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            if (isDropdownVisible) {
              setIsDropdownVisible(false);
              let url = '';
              if (personId) url = `${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${type}`;
              else if (companyId) url = `${COMPANY_OVERVIEW_ROUTE}${companyId}`;
              window.open(url, '_blank');
            } else {
              navigateTo();
            }
          }}
          onKeyPress={() => {
            if (isDropdownVisible) {
              setIsDropdownVisible(false);
              window.open(`${PERSONALITY_INSIGHTS_ROUTE}${personId}&personType=${type}`, '_blank');
            } else {
              navigateTo();
            }
          }}
          aria-label="redirectCard"
        >
          Open in New Tab
        </span>,
      key: '1',
    },
  ];

  const handleWidgetClick = () => {
    if (!isXiqUser()) return;
    setIsDropdownVisible((prev) => !prev);
  };
  return (
    <Wrapper
      color={color}
      diameter={diameter}
      diameterSm={diameterSm}
      margin={margin}
      marginSm={marginSm}
      ringPadding={ringPadding}
      ringPaddingSm={ringPaddingSm}
      diameterXsm={diameterXsm}
      className={classNames('person-without-holo', classNameWrapper, {
        searchWidget: inGlobalSearch,
      })}
    >
      <Dropdown
        menu={{ items }}
        trigger={['contextMenu']}
        visible={isDropdownVisible}
        onOpenChange={handleWidgetClick}
      >
        <Image
          className={`${imageClassName} ${!(personId === 0) || !(companyId === 0) ? 'c-pointer' : ''}` || 'c-pointer'}
          image={disclessPersonality && !topPeopleNutanix && !companyId && !industryWidget ? noPersonImage : logo}
          onClick={navigateTo}
          ringPosition={ringPosition}
          ringSize={ringSize}
        />
      </Dropdown>
      {showDrawerIcon && (
        <DrawerPeopleMoveReason personId={personId} showNotes isPersonCard />
      )}
      {
        showCheckBox && (
          <Checkbox
            checked={checked}
            onChange={selection}
          />
        )
      }
      {
        showRemoveIcon && (
          <div className="remove-btn">
            <Suspense
              fallback={<div />}
            >
              <RemoveIcon />
            </Suspense>
          </div>
        )
      }
      {personDesignation && showPersonName
        ? (
          <h4 className={classNames('span-heading', { topSpotLightPersonHover: topPersonHover })}>
            {personName}
            {' '}
            <span className="person-designation">{personDesignation}</span>
          </h4>
        ) : showPersonName
          ? <PersonName className={classNames('text-truncate', { personForHoverActive: personForHover })}>{personName}</PersonName>
          : null}
      {
        hidePersonality || disclessPersonality
          ? null : (
            <PersonalityWrapper color={persona} className="d-flex justify-content-center align-items-center name-wrapper">
              {discImage && (
                <div className="disc-img">
                  <Suspense
                    fallback={
                      <div />
                    }
                  >
                    <DiscIconSmall />
                  </Suspense>
                </div>
              )}
              <h4>
                {personality}
              </h4>
              <ArrowRightOutlined style={{
                color: '#fff',
                marginLeft: '5px',
                marginRight: '10px',
              }}
              />
            </PersonalityWrapper>
          )
      }
    </Wrapper>
  );
};
PeopleWidgetWithOutHolo.defaultProps = defaultProps;
export default PeopleWidgetWithOutHolo;
