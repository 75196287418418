import WhiteBotIcon from 'Components/common/assets/svgIcons/WhiteBotIcon';
import { isEditChatOpen, isGilroyTextHeight } from 'Selector/UISelector';
import {
  DEFAULT_GROUP_GILROY_IMAGE, TAG_CHARACTER, TAG_LIMIT,
  translateSkipAreas,
} from 'Utils/Constants';
import {
  SecondaryColor,
  maxMobile,
  maxTablet,
  maxWidth,
  primaryColor, primaryFont, retina,
  retinaOne,
  scrollbar, searchIconGilroyImge, wELight, wLight, wMBold, wMedium,
} from 'Utils/utilsStyle';
import classNames from 'classnames';
import React, {
  useState, useEffect, useRef, RefObject,
} from 'react';
import ContentEditable from 'react-contenteditable';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { actions as UiAction } from 'Slice/UISlice';
import { actions } from 'Slice/ChatbotV2Slice';
import { actions as flurryActions } from 'Slice/HelperSlice';
import { Base64 } from 'js-base64';
import {
  selectChatId, selectChatLoading, selectShowSearchPopup, selectTaggedItem, selectedConversationId,
  selectChatHistory, selectIsRegenerate, selectTaggedList, selectPersonCardLoading,
  selectIdToReplace,
  selectLimitPopupVisible,
  selectTagSetupLoading,
  selectIsNewChat,
  selectSearchQuery,
  selectPromptInProgress,
  selectPlaceholderToReplace,
  selectRecentTags,
  selectDrawerPreviewOpen,
  selectUniqueTagItemList,

} from 'Selector/ChatBotV2Selector';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  IsTablet,
  Retina125,
  extractSpanIdsFromHTML, extractSpanTagFromString, extractSpanTagsFromHTML, getCurrentTime,
  getLangCookie,
  getTimezoneInGMT,
  removeHtmlTags, valueToObject,
} from 'Utils/UtilityFunctions';
import { HistoryItem, TagItem } from 'Types/ChatBotV2Types';
import { Spin } from 'antd';
import GilroyTaggingLimitPopup from 'Components/common/modal/GilroyTaggingLimitPopup';
import { usePrevious } from 'Utils/hooks/usePrevious';
import { selectAiCertificationData, selectCertificationPrompt } from 'Selector/CertificationSelector';
import { actions as certificationActions } from 'Slice/CertificationSlice';
import { selectLoading } from 'Selector/SpotlightSelector';
import _ from 'lodash';
import InfoIconTwoRed from 'Components/common/assets/svgIcons/InfoIconTwoRed';
import { selectDisclessPersonality, selectGilroyDiscalimer, selectGilroyDiscalimerGlobal } from 'Selector/GlobalSearchContentSelector';
import HTMLReactParser from 'html-react-parser';
import SearchTaggingPopover from './chatbotTagging/SearchTaggingPopover';
import RecentTags from '../RecentTags';

const ContextMenuWrapper = styled.div<{ x: string, y: string }>`
  top: ${(props) => props.y};
  left: ${(props) => props.x};
  position: absolute;
  z-index: 999;
`;

const StyledWrapper = styled.div`
  position: relative;
  border-radius: 9px;
  border: 1px solid #D8D8D8;
  grammarly-extension {
    display: none;
  }
  &.changeHeight {
    position: relative;
    z-index: 0;
    padding: 3px 3px 40px 16px;
    @media all and (max-width: ${maxTablet}) {
      padding: 6px 12px 6px 12px;
    }
    grammarly-extension {
      display: none;
    }
    .textarea-input-gilroy {
      user-select: auto;
      white-space: pre-wrap;
      height: 204px;
      max-height: 204px;
      padding: 4px 2px;
      @media all and (max-width: ${maxWidth}), ${retina} {
        height: 152px;
        max-height: 152px;
      }
      @media all and (min-height: ${maxTablet}) {
        height: 204px;
        max-height: 204px;
      }
      @media all and (max-width: ${maxTablet}) {
        height: 75px;
        max-height: 75px;
        padding-bottom: 0px;
        line-height: 19px;
      }
      ${scrollbar};
      ::-webkit-scrollbar { width: 17px; } 
      ::-webkit-scrollbar-track { background-color: transparent; } 
      ::-webkit-scrollbar-thumb { background-color: #001760; border-radius: 20px; border: 7px solid transparent; background-clip: content-box; }
      border-radius: 0px !important;
      .prompt-input-style {
        line-height: 12px;
        display: inline;
        &.personAppend , &.companyAppend, &.groupAppend, &.documentAppend {
          @media all and (max-width: ${maxTablet}) {
            padding: 1px 7px 2px !important;
          }
        }
      }
    }
    background: #FFF;
    box-shadow: 0px 0px 19.53333px -2.29804px rgba(0, 0, 0, 0.19);
    border-color: ${primaryColor};
    .placeholder-content-editable {
      top: 10px !important;
      @media all and (max-width: ${maxTablet}) {
        top: 10px !important;
      }
    }
  }
  &.notHtmlCase {
    padding-right: 270px;
    @media all and (max-width: ${maxTablet}) {
      padding-right: 40px;
    }
    .textarea-input-gilroy {
      overflow: hidden;
      z-index: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      padding: 8px 0px 8px 16px;
      @media all and (max-width: ${maxTablet}) {
        line-height: 30px;
        padding: 3px 0px 4px 16px;
      }
      @media all and (max-width: ${maxMobile}) {
        line-height: 20px;
        padding: 8px 0px 8px 16px;
      }
    }
    &.hidePlaceholder {
      .placeholder-content-editable{
        display: none;
      }
    }
  }
  &.isSearchBarSmall {
    .textarea-input-gilroy {
      padding: 8px 0px 8px 16px;
      @media all and (max-width: ${maxTablet}) {
        padding: 4px 0px 4px 16px;
      }
      .global-search-div {
        .global-search-inner-div {
          &::before  {
            @media all and (max-width: ${maxTablet}) {
              top: 7.5px;
            }
          }
        }
      }
    }
  }

.textarea-input-gilroy {
  z-index: 1;
  position: relative;
  height: 40px;
  max-height: 40px;
  padding: 14px 16px;
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wLight};
  line-height: 25px;
  letter-spacing: -0.311px;
  color: #000;
  @media all and (max-width: ${maxWidth}), ${retina} {
    padding: 8px 16px;
  }
  @media all and (min-height: ${maxTablet}) {
    line-height: 23px;
  }
  @media all and (max-width: ${maxTablet}) {
    height: 34px;
    font-size: 12px; 
  }
  &:focus:not(.focus-visible) {
    outline: none;
  }
  .global-search-div {
    display: inline;
    .global-search-inner-div{
      display: inline-block;
      position: relative;
      width: 180px;
      height: 23px;
      margin: 0 3px;
      @media all and (max-width: ${maxTablet}) {
        width: 170px;
        height: 23px;
      }
      &::after {
        position: absolute;
        right: calc(100% - 30px);
        top: 5px;
        content: "";
        width: 1px;
        height: 17px;
        background-color: #DDD;
        @media all and (max-width: ${maxWidth}), ${retina} {
          top: 4px;
        }
        @media all and (max-width: ${maxWidth}), ${retinaOne} {
          top: 5px;
        }
        @media all and (max-width: ${maxTablet}) {
          height: 15px;
          top: 4px;
          right: calc(100% - 28px);
        }
      }
      &::before  {
        position: absolute;
        left: 10px;
        top: 6px;
        content: "";
        background-image: url(${searchIconGilroyImge});
        width: 12px;
        height: 13px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @media all and (max-width: ${maxWidth}), ${retina} {
          top: 5px;
        }
        @media all and (max-width: ${maxWidth}), ${retinaOne} {
          top: 6px;
        }
        @media all and (max-width: ${maxTablet}) {
          width: 11px;
          height: 12px;
          top: 4.5px;
        }
      }
    }
  }
  .global-search-input-content-editable {
    width: 180px;
    height: 23px;
    border-radius: 18px;
    border: 1px solid ${primaryColor};
    background: #FFF;
    color: #000;
    font-family: ${primaryFont};
    font-size: 14px;
    font-weight: ${wLight};
    line-height: 13px;
    letter-spacing: -0.297px;
    padding: 0px 10px 0px 34px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      padding-bottom: 1px;
    }
    @media all and (max-width: ${maxTablet}) {
      width: 170px;
      padding: 0px 10px 0px 29px;
      font-size: 12px;
    }
    &:focus:not(.focus-visible) {
      outline: none;
    }
    &:focus-visible {
      outline: none;
      border: 1px solid ${primaryColor};
    }
    &::placeholder {
      font-style: italic;
      font-size: 14px;
      line-height: 13px;
      color: #979797;
      font-weight: ${wELight} !important;
      letter-spacing: -0.297px;
      @media all and (max-width: ${maxTablet}){
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
  img, a, iframe {
    display: none;
  }
   span , .prompt-input-style {
    cursor: pointer;
    color: ${SecondaryColor};
    font-family: ${primaryFont};
    font-weight: ${wMBold};
    padding-right: 2px;
  }
  &:focus-visible {
    outline: none;
  }
}
.gilroy-caret-style {
  caret-color: transparent;
}
.placeholder-content-editable {
  &.taggedPlaceholder {
    color: ${SecondaryColor};
    font-weight: ${wMBold};
    line-height: 21px;
    font-style: normal;
  }
  z-index: 0;
  position: absolute;
  top: 33%;
  left: 14px;
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wLight};
  font-style: italic;
  line-height: 16px;
  letter-spacing: -0.583px;
  color: #888;
  @media all and (max-width: ${maxWidth}), ${retina} {
    top: 32%;
    font-size: 14px;
  }
  @media all and (min-height: ${maxTablet}) {
    top: 37%;
  }
  @media all and (max-width: ${maxTablet}){
    top: 26%;
    font-size: 12px;
  }
  @media all and (max-width: 375px){
    font-size: 10px;
  }
}
.bold-text {
  font-weight: ${wMBold};
  margin: 0 3px;
}
.generate-btn-gilroy {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 4px;
  right: 4px;
  padding: 4px 12px 3px;
  border-radius: 8px;
  background: ${primaryColor};
  color: #FFF;
  font-family: ${primaryFont};
  font-size: 16px;
  font-weight: ${wMedium};
  margin-bottom: 0;
  height: 32px;
  cursor: pointer;
  .ant-spin {
    width: 20px;
    height: 23px;
    .ant-spin-dot {
      width: inherit;
      height: inherit;
      .ant-spin-dot-item {
        background-color: #FFF;
      }
    }
  }
  @media all and (max-width: ${maxWidth}), ${retina} {
    font-size: 14px;
    line-height: 12px;
    padding: 6px 12px;
  }
  @media all and (min-height: ${maxTablet}) {
    font-size: 16px;
  }
  svg {
    width: 25px;
    height: 20px;
    margin-right: 5px;
    @media all and (max-width: ${maxWidth}), ${retina} {
      width: 23px;
      height: 18px;
    }
    @media all and (min-height: ${maxTablet}) {
      width: 25px;
      height: 20px;
    }
  }
  .disabled{
    pointer-events: none;
    opacity: 0.6;
  }
}
@media all and (max-width: ${maxTablet}) {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  .generate-btn-gilroy {
    width: 100%;
    bottom: -40px;
    right: 0px;
    padding: 8px 12px;
    border-radius: 6px;
  }
}
`;

type Props = {
  inputText: string
  setPromptInProgress: (a: string) => void
  setInputText: (a: string) => void
  setIsStopGenerating: (a: boolean) => void
  isStopGenerating: boolean
  setContentEditableRef: (a: RefObject<HTMLDivElement>) => void,
  tagItem?: TagItem
};

const defaultProps = {
  tagItem: {},
};

const CustomTextAreaGilroy = function CustomTextAreaGilroy(props: Props) {
  const {
    setPromptInProgress, inputText, setInputText,
    isStopGenerating, setIsStopGenerating, tagItem, setContentEditableRef,
  } = props;
  const host = window.location.href;
  const dispatch = useDispatch();
  const [valueHtml, setValueHtml] = useState('');
  const [toggleCaret, setTogglleCaret] = useState(false);
  const showLimitPopup = useSelector(selectLimitPopupVisible);
  const [valueWithoutHtml, setValueWithoutHtml] = useState('');
  const [query, setQuery] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [tagStartIndex, setTagStartIndex] = useState<number>(-1);
  const [spanId, setSpanId] = useState<number>(0);
  const [recentTagFocus, setRecentTagFocus] = useState(false);
  const [appendRecentTagId, setAppendRecentTagId] = useState(0);
  const [spanIdToFocus, setSpanIdToFocus] = useState<number>(0);
  const [pressBackspace, setPressBackspace] = useState<boolean>(false);
  const [arrowUpPress, setArrowUpPress] = useState<boolean>(false);
  const [lastTagRemove, setLastTagRemove] = useState<boolean>(false);
  const [searchAdded, setSearchAdded] = useState<boolean>(false);
  const [searchRemove, setSearchRemove] = useState<boolean>(false);
  const [selectionMode, setSelectionMode] = useState<boolean>(false);
  const [spansBeforeDelete, setSpansBeforeDelete] = useState<number[]>([]);
  const [isTextFieldUpdated, setIsTextFieldUpdated] = useState(false);
  const [searchPopupAppear, setSearchPopupAppear] = useState(false);
  const showSearchPopup = useSelector(selectShowSearchPopup);
  const [enterPressed, setEnterPressed] = useState(false);
  const [searchEnterPressed, setSearchEnterPressed] = useState(false);
  const [isPreAddTag, setIsPreAddTag] = useState(false);
  const [pastedContent, setPastedContent] = useState('');
  const [isSelectedRemoved, setIsSelectedRemoved] = useState(false);
  const isHeightTextArea = useSelector(isGilroyTextHeight);
  const idToReplace = useSelector(selectIdToReplace);
  const [idReplaced, setIdReplaced] = useState(false);
  const [clickedElement, setClickedElement] = useState<HTMLElement | null>(null);
  const [activePopoverTab, setActivePopoverTab] = useState<number>(0);
  const [clickedElementIndex, setClickedElementIndex] = useState(0);
  const [placeholderChange, setPlaceholderChange] = useState(false);
  const chatLoading = useSelector(selectChatLoading);
  const personCardLoading = useSelector(selectPersonCardLoading);
  const tagSetupLoading = useSelector(selectTagSetupLoading);
  const taggedList = useSelector(selectTaggedList);
  const selectedConvoId = useSelector(selectedConversationId);
  const taggedItem = useSelector(selectTaggedItem);
  const isNewChat = useSelector(selectIsNewChat);
  const [isTag, setIsTag] = useState<boolean>(false);
  const [tagModeOn, setTagModeOn] = useState<boolean>(false);
  const [searchbarValue, setSearchbarValue] = useState('');
  const [escapePress, setEscapePress] = useState(false);
  const placeholderToReplace = useSelector(selectPlaceholderToReplace);
  const id = useSelector(selectChatId);
  const prevQuery = useSelector(selectSearchQuery);
  const isRegenerate = useSelector(selectIsRegenerate);
  const chatHistory = useSelector(selectChatHistory);
  const isEditChatFlag = useSelector(isEditChatOpen);
  const promptInProgress = useSelector(selectPromptInProgress);
  const certificationPrompt = useSelector(selectCertificationPrompt);
  const aiCertificationData = useSelector(selectAiCertificationData);
  const contentEditableRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const customTextAreaRef = useRef<HTMLDivElement>(null);
  const prevChatLoading = usePrevious(chatLoading);
  const prevSelectedConvoId = usePrevious(selectedConvoId);
  const prevValueHtml = usePrevious(valueHtml);
  const personRegex = /<span class="prompt-input-style personAppend">.*?<\/span>/;
  const companyRegex = /<span class="prompt-input-style companyAppend">.*?<\/span>/;
  const groupRegex = /<span class="prompt-input-style groupAppend">.*?<\/span>/;
  const docRegex = /<span class="prompt-input-style docAppend">.*?<\/span>/;
  const getCurrentNode = () => document.getElementById('custom-text-editable') as HTMLElement;

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const spotlightLoading = useSelector(selectLoading);
  const drawerPreviewOpen = useSelector(selectDrawerPreviewOpen);
  const [recentTagsState, setRecentTagState] = useState(false);
  const uniqueTagList = useSelector(selectUniqueTagItemList);
  const recentTags = useSelector(selectRecentTags);
  const { showDisclaimer, disclaimerText } = useSelector(selectGilroyDiscalimer);
  const { showDisclaimer: showDisclaimerGlobal, disclaimerText: disclaimerTextGlobal } = useSelector(selectGilroyDiscalimerGlobal);
  const [popUpClose, setPopUpClose] = useState(false);
  const searchBarInputById = document.getElementById('global-search-input') as HTMLInputElement;
  const searchBarInputDivById = document.getElementById('global-search-input-div') as HTMLDivElement;
  const defaultGlobalSearchDiv = '<div id="global-search-input-div" class="global-search-div" contenteditable="false" bis_skin_checked="1"><div class="global-search-inner-div" bis_skin_checked="1"><input id="global-search-input" placeholder="Search your target" class="global-search-input-content-editable" autocomplete="off" maxlength="50" contenteditable="false" bis_skin_checked="1"></div></div>';
  const globalSearchDiv = searchBarInputDivById
    ? searchBarInputDivById?.outerHTML : defaultGlobalSearchDiv;
  const prevGlobalSearchDiv = usePrevious(globalSearchDiv);
  const globalSearchDivWithBR = `${globalSearchDiv}<br>`;
  const startTime = getCurrentTime();
  const pathName = window.location.pathname;
  const [history, setHistory] = useState([] as HistoryItem[]);
  const [isUndo, setIsUndo] = useState(false);
  const [beforeCutText, setBeforeCutText] = useState('');
  const [isTextSelected, setIsTextSelected] = useState(false);
  const undoDisable = !history.length;
  const [pasteDisable, setPasteDisable] = useState(true);
  const cutDisable = !isTextSelected;
  const copyDisable = !isTextSelected;
  const [showMenuButton, setShowMenuButton] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const disclessPersonality = useSelector(selectDisclessPersonality);
  const [bypassCaret, setBypassCaret] = useState(false);

  const pushToHistory = (item: HistoryItem) => {
    if (!item.text.includes('id="%') && (!history.length || item.text !== history[history.length - 1].text)) {
      if (history.length >= 6) {
        history.shift();
      }
      setHistory([...history, item]);
      dispatch(actions.setHistory([...history, item]));
    }
  };

  const resetHistory = () => {
    setHistory([]);
    dispatch(actions.setHistory([]));
  };

  const debouncedPushToHistory = _.debounce(pushToHistory, 500);

  useEffect(() => {
    if (beforeCutText) {
      const historyObj: HistoryItem = {
        text: beforeCutText,
        placeholder: placeholderToReplace,
        allTags: taggedList,
        uniqueTags: uniqueTagList,
      };
      setBeforeCutText('');
      pushToHistory(historyObj);
    }
  }, [beforeCutText]);

  useEffect(() => {
    if (isUndo) {
      if (history.length > 1) {
        const {
          text, placeholder, allTags, uniqueTags,
        } = history[history.length - 2];
        setValueHtml(`${text}`);
        dispatch(actions.setTagsList({ allTags, uniqueTags }));
        dispatch(actions.setHistory(history.slice(0, -1)));
        setHistory((prevHistory) => prevHistory.slice(0, -1));
        dispatch(actions.setPlaceholderToReplace(placeholder));
      } else if (history.length === 1) {
        setValueHtml('');
        dispatch(actions.setTagsList({ allTags: [], uniqueTags: [] }));
        setHistory([]);
        dispatch(actions.setHistory([]));
        dispatch(actions.setPlaceholderToReplace(''));
      }
      setIsUndo(false);
    }
  }, [isUndo]);

  const generateDisable = chatLoading || !valueWithoutHtml.trim().length
    || personCardLoading || tagSetupLoading || isEditChatFlag;

  const timezone = getTimezoneInGMT();

  const isGilroyV3 = disclessPersonality ? (!window.location.pathname.includes('feed') && !window.location.pathname.includes('person'))
    : !window.location.pathname.includes('feed');
  const isTab = IsTablet();
  const isRetina125 = Retina125();
  const pageType = () => {
    switch (true) {
      case (pathName.includes('company')) && !pathName.includes('comparecompany'):
        return 'company';
      case pathName.includes('group'):
        return 'group';
      case pathName.includes('industry') || pathName.includes('industries'):
        return 'industry';
      case pathName.includes('person'):
        return 'person';
      case pathName.includes('people'):
        return 'people';
      case pathName.includes('document'):
        return 'document';
      case pathName.includes('sri'):
        return 'sri';
      default:
        return 'feed';
    }
  };

  const classType = () => {
    switch (true) {
      case (pathName.includes('company')):
        return 'companyAppend';
      case pathName.includes('group'):
        return 'groupAppend';
      case pathName.includes('person'):
        return 'personAppend';
      case pathName.includes('document'):
        return 'docAppend';
      default:
        return 'companyAppend';
    }
  };

  const source = (event: string) => {
    switch (true) {
      case event.includes('company'):
        return 'company_gilroy';
      case event.includes('group'):
        return 'group_gilroy';
      case event.includes('person'):
        return 'person_gilroy';
      case event.includes('Document'):
        return 'doc_gilroy';
      default:
        return 'feed';
    }
  };

  const pageSource = () => {
    switch (true) {
      case pathName.includes('company') || pathName.includes('companies'):
        return 'company';
      case pathName.includes('group'):
        return 'group';
      case pathName.includes('industry') || pathName.includes('industries'):
        return 'industry';
      case pathName.includes('person'):
        return 'person';
      case pathName.includes('people'):
        return 'people';
      case pathName.includes('document'):
        return 'document';
      case pathName.includes('sri'):
        return 'sri';
      case pathName.includes('search'):
        return 'search';
      default:
        return 'feed';
    }
  };

  const onRemoveSearchBar = () => {
    if (searchBarInputDivById) {
      setQuery('');
      setTagStartIndex(-1);
      setSearchAdded(false);
      setSearchRemove(true);
      dispatch(actions.setPlaceholderToReplace(''));
    }
  };

  useEffect(() => {
    if (pastedContent?.length) {
      if (certificationPrompt?.prompt === pastedContent) {
        dispatch(certificationActions.setCertificationPrompt({
          ...certificationPrompt, isPasted: true,
        }));
      }
      setPastedContent('');
    }
  }, [pastedContent]);

  useEffect(() => {
    if (!isHeightTextArea && valueHtml === '<br>' && !valueWithoutHtml.length) {
      setValueHtml('');
    }
  }, [valueHtml, isHeightTextArea]);

  useEffect(() => {
    if (promptInProgress && chatLoading) {
      setPromptInProgress(promptInProgress);
    }
  }, [promptInProgress]);

  useEffect(() => {
    if (tagItem?.id && pageType() !== 'document' && !chatLoading) {
      dispatch(actions.setTaggedItem(tagItem));
      dispatch(actions.setUniqueTaggedItem(tagItem));
    }
    return () => {
      setValueHtml('');
    };
  }, []);

  useEffect(() => {
    if (contentEditableRef?.current) {
      setContentEditableRef(contentEditableRef);
    }
  }, [contentEditableRef]);

  const onChangeText = (e: React.FormEvent<HTMLDivElement>) => {
    if (!isHeightTextArea && appendRecentTagId) {
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
    }
    dispatch(actions.setIsNewChat(false));
    setIsTextFieldUpdated(true);
    if (showSearchPopup) {
      dispatch(actions.setSearchPopup(false));
    }
    const { value } = e.target as HTMLTextAreaElement;
    const latestSearchBarInputDivById = document.getElementById('global-search-input-div') as HTMLDivElement;
    if (!(value.includes(globalSearchDiv)
      || value.includes(latestSearchBarInputDivById?.outerHTML || globalSearchDiv))
      && placeholderToReplace) {
      dispatch(actions.setPlaceholderToReplace(''));
    }
    if (!value.length) {
      dispatch(actions.resetTagItemsList());
      dispatch(actions.setPlaceholderToReplace(''));
    }
    const spans = extractSpanIdsFromHTML(value);
    const prevSpans = extractSpanIdsFromHTML(prevValueHtml ?? '');
    if ((isDelete && spans.length !== spansBeforeDelete.length) || selectionMode) {
      if (!spans.length && spansBeforeDelete.length) {
        dispatch(actions.resetTagItemsList());
        setLastTagRemove(true);
      } else {
        const spansBefore = spansBeforeDelete;
        const spansAfter = spans;
        for (let i = 0; i < spansAfter.length; i += 1) {
          const index = spansBefore.indexOf(spansAfter[i]);
          if (index !== -1) {
            spansBefore.splice(index, 1);
          }
        }
        if (spansBefore.length && !recentTagsState) {
          const missingIds = prevSpans.filter((i) => !spans.includes(i));
          const missingIdsInTaggedList = missingIds.filter((missingId) => {
            const isIdExists = taggedList
              .reduce((acc, curr) => (curr.id === missingId ? acc + 1 : acc), 0);
            return isIdExists <= 1;
          });
          missingIdsInTaggedList.forEach((missingId) => {
            dispatch(actions.removeUniqueTaggedItem(Number(missingId)));
          });
          spansBefore.forEach((x) => dispatch(actions.removeTagItem(x)));
        } else {
          setIsSelectedRemoved(true);
        }
      }
    } else if (prevSpans.length !== spans.length) {
      const spansBefore = prevSpans;
      const spansAfter = spans;
      for (let i = 0; i < spansAfter.length; i += 1) {
        const index = spansBefore.indexOf(spansAfter[i]);
        if (index !== -1) {
          spansBefore.splice(index, 1);
        }
      }
      if (spansBefore.length) {
        const missingIds = prevSpans.filter((i) => !spans.includes(i));
        const missingIdsInTaggedList = missingIds.filter((missingId) => {
          const isIdExists = taggedList
            .reduce((acc, curr) => (curr.id === missingId ? acc + 1 : acc), 0);
          return isIdExists <= 1;
        });
        missingIdsInTaggedList.forEach((missingId) => {
          dispatch(actions.removeUniqueTaggedItem(Number(missingId)));
        });
        spansBefore.forEach((x) => dispatch(actions.removeTagItem(x)));
      }
    }
    setSelectionMode(false);
    setSpansBeforeDelete([]);
    if (!valueHtml.length && appendRecentTagId) {
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
    }
    const replace = value.replaceAll(/<[^>]*>/g, '').replace(/&nbsp;/g, '')
      .replace(/</g, '<').replace(/>/g, '>')
      .replace(/&/g, '&');
    setValueWithoutHtml(replace);
    if (isTag) {
      searchBarInputDivById?.remove();
      if (placeholderToReplace) {
        setValueHtml(value.replace(globalSearchDiv, placeholderToReplace));
        const span = extractSpanTagFromString(placeholderToReplace);
        if (span?.id) {
          dispatch(actions.setUniqueTaggedItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
          dispatch(actions.pushTagItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
        }
        dispatch(actions.setPlaceholderToReplace(''));
      } else {
        setValueHtml(value.replace(globalSearchDiv, ''));
      }
      setTagStartIndex(-1);
    } else if (taggedList.length === TAG_LIMIT && value.includes(globalSearchDiv)) {
      setValueHtml(value.replace(globalSearchDiv, ''));
    } else {
      const historyObj: HistoryItem = {
        text: value,
        placeholder: placeholderToReplace,
        allTags: taggedList,
        uniqueTags: uniqueTagList,
      };
      setValueHtml(value);
      debouncedPushToHistory(historyObj);
    }
    if (isTag) setTagModeOn(true);
    setIsTag(false);
  };

  useEffect(() => {
    if (spansBeforeDelete.length && isDelete && isSelectedRemoved) {
      const taggedIds = taggedList.map((obj) => obj.id);
      spansBeforeDelete.forEach((tagId) => {
        const index = taggedIds.indexOf(tagId);
        if (index !== -1) {
          taggedIds[index] = 0;
        }
      });
      if (taggedIds?.length) {
        taggedIds.forEach((x) => {
          const isIdExists = taggedList
            .reduce((acc, curr) => (curr.id === x ? acc + 1 : acc), 0);
          if (isIdExists <= 1) {
            dispatch(actions.removeUniqueTaggedItem(Number(x)));
          }
          if (x) {
            dispatch(actions.removeTagItem(x));
          }
        });
      }
      setIsSelectedRemoved(false);
    }
  }, [spansBeforeDelete]);

  const taggingInPrompt = (regex: RegExp | string) => {
    dispatch(actions.resetTagItemsList());
    dispatch(actions.setPlaceholderToReplace(''));

    const taggedPrompt = inputText.replace(
      regex,
      `<span key="${tagItem?.type || ''}" data-color="${tagItem?.color ?? ''}" class="prompt-input-style c-pointer" id="${tagItem?.id || 0}" contenteditable="false">@${tagItem?.name ?? ''}</span>`,
    );
    setValueHtml(taggedPrompt);
    if (tagItem?.id) {
      dispatch(actions.setUniqueTaggedItem(tagItem));
      dispatch(actions.pushTagItem(tagItem));
      pushToHistory({
        text: taggedPrompt,
        allTags: taggedList,
        uniqueTags: uniqueTagList,
        placeholder: placeholderToReplace,
      });
    }
  };

  useEffect(() => {
    if (inputText) {
      resetHistory();
      const parser = new DOMParser();
      const doc = parser.parseFromString(inputText, 'text/html');
      const spanElements = doc.querySelectorAll('span');
      let certificationClass = '';
      let taggingClass = '';
      let tagSpanElement = '';

      spanElements?.forEach((spanElement) => {
        spanElement.classList.forEach((word) => {
          if (word.includes('execute_prompt')) {
            certificationClass = word;
          } else if (word.includes('Append') && !taggingClass.includes(classType())) {
            taggingClass = word;
            tagSpanElement = spanElement.outerHTML;
          }
        });
      });
      tagSpanElement = tagSpanElement.replace(/=""/g, '');
      if (isRegenerate) {
        setValueHtml(inputText);
        pushToHistory({
          text: inputText,
          allTags: taggedList,
          uniqueTags: uniqueTagList,
          placeholder: placeholderToReplace,
        });
        dispatch(actions.resetTagItemsList());
        dispatch(actions.setPlaceholderToReplace(''));
        const spans = extractSpanTagsFromHTML(inputText);
        spans.forEach((span) => {
          dispatch(actions.pushTagItem(span));
          dispatch(actions.setUniqueTaggedItem(span));
        });
      } else if ((personRegex.test(inputText) || taggingClass === 'personAppend') && pageType() === 'person') {
        dispatch(actions.personSetup({ personId: tagItem?.id }));
        taggingInPrompt(tagSpanElement);
      } else if ((companyRegex.test(inputText) || taggingClass === 'companyAppend') && pageType() === 'company') {
        dispatch(actions.companySetup({ companyId: tagItem?.id }));
        taggingInPrompt(tagSpanElement);
      } else if ((groupRegex.test(inputText) || taggingClass === 'groupAppend') && pageType() === 'group') {
        dispatch(actions.groupSetup({ groupId: tagItem?.id }));
        taggingInPrompt(tagSpanElement);
      } else if ((docRegex.test(inputText) || taggingClass === 'docAppend') && pageType() === 'document') {
        dispatch(actions.companySetup({ companyId: tagItem?.id }));
        taggingInPrompt(tagSpanElement);
      } else {
        dispatch(actions.resetTagItemsList());
        dispatch(actions.setPlaceholderToReplace(''));
        setValueHtml(inputText);
        pushToHistory({
          text: inputText,
          allTags: taggedList,
          uniqueTags: uniqueTagList,
          placeholder: placeholderToReplace,
        });
      }
      if (!isRegenerate) {
        if (certificationClass.length) {
          dispatch(certificationActions.setCertificationPrompt({
            source: source(certificationClass) as string,
            eventType: certificationClass,
            prompt: inputText,
            isPasted: true,
          }));
        } else {
          dispatch(certificationActions.setCertificationPrompt({
            source: '',
            eventType: '',
            prompt: '',
            isPasted: false,
          }));
        }
        dispatch(actions.setCertificationPrompt(''));
      }
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
      if (contentEditableRef.current) {
        contentEditableRef.current.focus();
      }
      setInputText('');
    }
  }, [inputText]);

  useEffect(() => {
    if (isNewChat) {
      dispatch(actions.setHistory([]));
      setHistory([]);
      dispatch(actions.resetTagItemsList());
      dispatch(actions.setPlaceholderToReplace(''));
      setValueHtml('');
      setValueWithoutHtml('');
      if (tagItem?.id && !chatLoading && pageType() !== 'document') {
        dispatch(actions.setTaggedItem(tagItem));
        dispatch(actions.setUniqueTaggedItem(tagItem));
      }
    }
  }, [isNewChat]);

  useEffect(() => {
    if (tagItem?.id) {
      dispatch(actions.resetTagItemsList());
      dispatch(actions.setPlaceholderToReplace(''));
      setValueHtml('');
      setValueWithoutHtml('');
      if (tagItem?.id && !chatLoading && pageType() !== 'document') {
        dispatch(actions.setTaggedItem(tagItem));
        dispatch(actions.setUniqueTaggedItem(tagItem));
      }
    }
  }, [tagItem?.name]);

  const decodeHtmlEntities = (html: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
  };

  const handleGenerateChat = () => {
    let list = [...taggedList];
    if (!generateDisable) {
      let refinedValue = '';
      if (placeholderToReplace && valueHtml.includes(globalSearchDiv)) {
        const span = extractSpanTagFromString(placeholderToReplace);
        refinedValue = valueHtml.replace(/&nbsp;/g, ' ').replace(globalSearchDiv, placeholderToReplace);
        if (span?.id) {
          dispatch(actions.pushTagItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
          dispatch(actions.setUniqueTaggedItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
          list.push({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset?.color || '',
            image: span.dataset?.image || '',
            name: span.innerText.replace(/@/g, ''),
          } as TagItem);
          dispatch(actions.setPlaceholderToReplace(''));
        }
      } else {
        refinedValue = valueHtml.replace(/&nbsp;/g, ' ').replace(globalSearchDiv, '');
      }
      setValueHtml(refinedValue);
      if (!chatLoading && valueWithoutHtml.trim().length) {
        const flurry = isRegenerate ? 'xcgpt_regenerate_complete' : 'xcgpt_generate';
        dispatch(actions.setSearchPopup(false));
        if (selectedConvoId === -1) {
          dispatch(actions.setSelectedConversationId(0));
        }
        setPromptInProgress(refinedValue);
        setIsStopGenerating(false);
        setInputText('');
        list = list.filter((obj, index, array) => array.findIndex(
          (o) => o.id === obj.id,
        ) === index);
        const companyIds = list.filter((item) => item?.type === 'company').map((company) => company.id).join(',');
        const personIds = list.filter((item) => item?.type === 'person').map((company) => company.id).join(',');
        const groupIds = list.filter((item) => item?.type === 'group').map((company) => company.id).join(',');
        const docIds = list.filter((item) => item?.type === 'document').map((company) => company.id).join(',');
        dispatch(actions.setGeneratingChannelId(selectedConvoId === -1 ? 0 : selectedConvoId));
        const image = (pageType() === 'group' && !list.length) || list[0]?.type === 'group' ? DEFAULT_GROUP_GILROY_IMAGE : list[0]?.image ?? '';
        dispatch(actions.chatbot({
          id,
          prompt: Base64.encode(decodeHtmlEntities(refinedValue)),
          source: pageSource(),
          channelId: selectedConvoId === -1 ? 0 : selectedConvoId,
          regenerate: isRegenerate ? 1 : 0,
          responseId: isRegenerate
            ? parseInt(chatHistory[chatHistory.length - 1]?.responseId, 10) : 0,
          ...(companyIds ? { taggedCompanies: companyIds } : {}),
          ...(groupIds ? { taggedGroups: groupIds } : {}),
          ...(personIds ? { taggedPersons: personIds } : {}),
          ...(docIds ? { taggedDocuments: docIds } : {}),
          ...((selectedConvoId === -1 || selectedConvoId === 0) && (list?.length || pageType() === 'group') ? {
            avatarColor: showDisclaimer ? '' : list[0]?.type === 'company' ? '#979797' : list[0]?.color || '',
            avatarImage: showDisclaimer ? '' : image,
          } : {}),
        }));
        dispatch(actions.changeOrderRecentTags(list));
        dispatch(UiAction.isGilroyTextHeightIncrease(false));
        dispatch(actions.setIsRegenerate(false));
        dispatch(flurryActions.callFlurryEvent(
          valueToObject(flurry, startTime, {
            searchQuery: refinedValue,
          }),
        ));
        dispatch(actions.resetTagging());
        if (certificationPrompt?.isPasted) {
          dispatch(certificationActions.aiCertificationAction({
            type: certificationPrompt?.eventType,
            source: certificationPrompt?.source,
            data: aiCertificationData,
          }));
        }
      }
    }
  };

  const removeEmptyTextNodes = (node: Node) => {
    for (let i = 0; i < node?.childNodes?.length; i += 1) {
      const child = node?.childNodes[i];
      if (child.nodeType === Node.TEXT_NODE && !child?.nodeValue?.trim() && (isSafari || (!isSafari && child?.nextSibling?.nodeName !== 'SPAN' && child?.previousSibling?.nodeName !== 'SPAN'))) {
        child?.parentNode?.removeChild(child);
        i -= 1;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        removeEmptyTextNodes(child);
      }
    }
  };

  useEffect(() => {
    if (isSafari) {
      const hasBRUsingChildNodes = Array.from(getCurrentNode()?.childNodes || []).some((node) => node.nodeName === 'BR');

      if (hasBRUsingChildNodes) {
        const brNodes = getCurrentNode().getElementsByTagName('br');
        Array.from(brNodes).forEach((br) => br?.parentNode?.removeChild(br));
      }
    }
  }, [valueHtml]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (getCurrentNode().lastElementChild?.nodeName === 'BR' && (event.target as HTMLElement).id !== 'global-search-input' && !valueHtml.endsWith(globalSearchDivWithBR) && event.key === 'Backspace') {
      getCurrentNode().lastElementChild?.remove();
      const newText = document.createTextNode('');
      getCurrentNode().appendChild(newText);
    }
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const selectedNode = selection?.focusNode?.parentNode;
    let flag = false;
    if (range && selection && !range?.collapsed && (event.key.length === 1 || event.key === 'Backspace' || event.key === 'Delete') && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
      if (range.startContainer.parentElement?.nodeName === 'SPAN' && range.startContainer.parentElement.id) {
        setIsSelectedRemoved(true);
        range.startContainer.parentElement.remove();
        if (!isSafari) {
          const newText = document.createTextNode('');
          range.insertNode(newText);
        }
      }
      if (isSafari) {
        document.execCommand('delete', false);
      } else {
        document.execCommand('insertText', false, '');
      }
      if (isSafari && selectedNode && selectedNode.nodeName !== 'DIV') {
        const parent = selectedNode?.parentNode;
        const parentElement = selectedNode?.parentNode as Element;
        if (parentElement) {
          if (!parentElement?.classList?.toString().includes("custom-text-area-gilroy")) {
            parent?.removeChild(selectedNode);
          }
        }
        flag = true;
      }

      const newText = document.createTextNode('');
      range.insertNode(newText);
      range.setStartAfter(newText);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
      if (event.key === 'Backspace' || event.key === 'Delete') {
        event.preventDefault();
      }
    } else {
      setIsSelectedRemoved(false);
    }

    if (valueHtml.includes(globalSearchDiv)) {
      setIsFocus(true);
    }
    if (event.key === 'Enter' && !event.shiftKey && document.activeElement?.nodeName !== 'INPUT') {
      event.preventDefault();
      setEnterPressed(true);
    }
    if (event.key === 'Escape') {
      event.preventDefault();
    }
    if (event.key === 'Backspace') {
      setTagModeOn(false);
      const extractedSpans = extractSpanIdsFromHTML(contentEditableRef.current?.innerHTML ?? '');
      const node = getCurrentNode();
      if (extractedSpans.length && (event.target as HTMLElement).id !== 'global-search-input' && !flag) {
        setIsDelete(true);
        setSpansBeforeDelete(extractedSpans);
      }
      if (((event.target as HTMLElement).id !== 'global-search-input' && !isSafari) || isSafari) {
        removeEmptyTextNodes(node);
      }
    }
    if (event.key === 'Delete') {
      setIsDelete(true);
      setSpansBeforeDelete(extractSpanIdsFromHTML(valueHtml ?? ''));
    }
    if (event.key === TAG_CHARACTER && !isTag) {
      setSearchbarValue('');
      setIsTag(true);
    }

    if (event.ctrlKey || event.metaKey) {
      if (event.key === 'v' || event.key === 'V') {
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_paste_content', startTime, {
            host,
          }),
        ));
      }
      if (event.key === 'c' || event.key === 'C') {
        const s = window.getSelection()?.toString();
        if (s) {
          dispatch(flurryActions.callFlurryEvent(
            valueToObject('xcgpt_copy_content', startTime, {
              host,
            }),
          ));
          setBeforeCutText(getCurrentNode().innerHTML);
        }
      }
      if (event.key === 'z' || event.key === 'Z') {
        event.preventDefault();
        setIsUndo(true);
      }
      if (event.key === 'x' || event.key === 'X') {
        const s = window.getSelection()?.toString();
        if (s) {
          dispatch(flurryActions.callFlurryEvent(
            valueToObject('xcgpt_cut_content', startTime, {
              host,
            }),
          ));
          setBeforeCutText(getCurrentNode().innerHTML);
        } else {
          event.preventDefault();
        }
      }
      if (event.key === 'a' || event.key === 'A') {
        setSpansBeforeDelete(extractSpanIdsFromHTML(valueHtml ?? ''));
        setSelectionMode(true);
      } else if (event.key !== 'c' && event.key !== 'C' && event.key !== 'v' && event.key !== 'V' && event.key !== 'z' && event.key !== 'Z' && event.key !== 'x' && event.key !== 'X') {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    if (enterPressed && valueHtml?.trim().length > 0) {
      handleGenerateChat();
    }
    setEnterPressed(false);
  }, [enterPressed]);

  const handlePasteInGpt = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain');
    const newText = pastedText.substring(0, 5000 - valueWithoutHtml.length);
    setPastedContent(newText);
    const sanitizedText = removeHtmlTags(newText);
    document.execCommand('insertText', false, sanitizedText);
  };

  const tagIndexHandler = (value: string) => {
    const node = getCurrentNode();
    if (node) {
      let index = -1;
      for (let i = 0; i < value.length; i += 1) {
        if (prevValueHtml?.length) {
          if (value[i] === '@' && prevValueHtml[i] !== '@') {
            const before = value.slice(0, i);
            const after = value.slice(i + 2);
            if (!before.endsWith('contenteditable="false">') && !before.endsWith('">') && !after.startsWith(']</span>') && !before.endsWith('Append">[')) {
              index = i;
              break;
            }
          }
        } else if (value[i] === '@') {
          const before = value.slice(0, i);
          const after = value.slice(i + 2);
          if (!before.endsWith('contenteditable="false">') && !after.startsWith(']</span>')) {
            index = i;
            break;
          }
        }
      }
      if (index > -1) {
        setTagStartIndex(index);
      }
    }
  };

  const handleTag = () => {
    const first = valueHtml.slice(0, tagStartIndex);
    let second = valueHtml.slice(tagStartIndex + 1);

    if (!isSafari && !second) {
      second = '<br>';
    }
    const valueToSet = first + globalSearchDiv + second;
    setValueHtml(valueToSet);
    pushToHistory({
      text: valueToSet,
      allTags: taggedList,
      uniqueTags: uniqueTagList,
      placeholder: placeholderToReplace,
    });
    setTagModeOn(false);
    setSearchAdded(true);
  };

  useEffect(() => {
    if (tagModeOn) tagIndexHandler(valueHtml);
    const searchBarRef = document.getElementById('custom-text-editable')?.querySelector('#global-search-input') as HTMLInputElement;
    if (searchAdded) {
      if (searchbarValue && searchBarRef) {
        searchBarRef.value = searchbarValue;
        setQuery(searchbarValue);
      }
      searchBarRef?.focus();
      const tempElement = document.createElement('div');
      tempElement.innerHTML = valueHtml;
      const inputElement = tempElement.querySelector('#global-search-input');
      if (inputElement?.outerHTML === searchBarRef?.outerHTML) {
        setSearchAdded(false);
      }
    }
    dispatch(actions.setIsNewChat(false));
    if (valueWithoutHtml === ' ' && tagItem?.id && pageType() !== 'document' && !arrowUpPress && !lastTagRemove && !isRegenerate && !taggedList.length) {
      dispatch(actions.setTaggedItem(tagItem));
      dispatch(actions.setUniqueTaggedItem(tagItem));
    }
    setArrowUpPress(false);
    setLastTagRemove(false);
  }, [valueHtml]);

  useEffect(() => {
    if (tagStartIndex > -1) {
      if (tagModeOn) {
        handleTag();
      }
      const searchBarRef = document.getElementById('custom-text-editable')?.querySelector('#global-search-input') as HTMLInputElement;
      searchBarRef?.focus();
    }
  }, [tagStartIndex]);

  const performSearch = () => {
    const searchQuery = searchBarInputById.value.trim();
    if (searchQuery.length && searchQuery !== prevQuery) {
      dispatch(actions.setSearchPopup(true));
      dispatch(actions.globalSearchResults({
        query: searchQuery,
        screenName: 'Gilroy',
        timezone,
        defaultLanguage: getLangCookie(),
      }));
    }
    dispatch(flurryActions.callFlurryEvent(
      valueToObject(`xcgpt_search_popup_open_${placeholderToReplace.includes('id=') ? 'previous' : 'new'}TAG`, 0, {
        host,
      }),
    ));
  };

  useEffect(() => {
    if (searchEnterPressed) {
      performSearch();
      setSearchEnterPressed(false);
    }
  }, [searchEnterPressed]);

  const handleEnter = (e: KeyboardEvent) => {
    if (e?.key === 'Enter') {
      setIsFocus(true);
      setSearchEnterPressed(true);
      searchBarInputById.value = searchBarInputById?.value?.trim();
      e.preventDefault();
    }
    if (e?.key === 'Backspace') {
      if (!searchBarInputById.value.length) {
        onRemoveSearchBar();
        setPressBackspace(true);
      }
    }
    if (e?.key === 'Escape') {
      setEscapePress(true);
    }
  };

  const handleClick = (e: MouseEvent) => {
    if (e) {
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
      e.stopPropagation();
    }
  };

  const handleInput = (e: Event) => {
    if (e) {
      const inputElement = e.target as HTMLInputElement;
      setQuery(inputElement.value.trim());
    }
  };

  useEffect(() => {
    if (isFocus && (searchPopupAppear || isDelete || idReplaced)) {
      const searchBar = document.getElementById('global-search-input') as HTMLInputElement;
      if (searchBar) {
        searchBar.value = query;
      }
      setIsFocus(false);
      if (idReplaced) {
        setIdReplaced(false);
        searchBar?.focus();
      }
      if (searchPopupAppear) {
        setSearchPopupAppear(false);
      }
    }
    setIsDelete(false);
    setIsSelectedRemoved(false);
  }, [isFocus, searchPopupAppear, idReplaced]);

  useEffect(() => {
    const searchBar = document.getElementById('global-search-input') as HTMLInputElement;
    if (searchBar && query) {
      searchBar.value = query;
    }
  }, [query]);

  useEffect(() => {
    if (searchBarInputById) {
      searchBarInputById.addEventListener('keydown', handleEnter);
      searchBarInputById.addEventListener('input', handleInput);
      searchBarInputById.addEventListener('click', handleClick);
    }
    return () => {
      if (searchBarInputById) {
        searchBarInputById.removeEventListener('keydown', handleEnter);
        searchBarInputById.removeEventListener('click', handleClick);
        searchBarInputById.removeEventListener('input', handleInput);
      }
    };
  }, [searchBarInputById, valueHtml]);

  const onSearchPopupCross = () => {
    dispatch(actions.setSearchPopup(false));
  };

  useEffect(() => {
    if (valueHtml.includes(globalSearchDiv) && placeholderToReplace) {
      const searchBarInput = document.getElementById('global-search-input') as HTMLInputElement;
      const span = extractSpanTagFromString(placeholderToReplace);
      const name = span?.innerText.replace(/@/g, '');
      if (name !== 'Person name' && name !== 'Company name' && name !== 'Buyergroup') {
        searchBarInput.value = name || '';
      }
    }
    if (appendRecentTagId) {
      setValueHtml(valueHtml.replace(`id="%${appendRecentTagId}">`, `id="%${appendRecentTagId}" contenteditable="false">`));
      setSpanId(appendRecentTagId);
      setAppendRecentTagId(0);
    }
  }, [appendRecentTagId]);

  const isTextareaFocused = () => {
    const textarea = document.getElementById('global-search-input');
    return document.activeElement === textarea;
  };

  const getActiveElement = (): string => {
    switch (true) {
      case activePopoverTab === 1:
        return 'person-append';
      case activePopoverTab === 2:
        return 'company-append';
      case activePopoverTab === 3:
        return 'group-append';
      case activePopoverTab === 4:
        return 'document-append';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (pageType() === 'feed' && !isTextFieldUpdated && !recentTagsState) {
      dispatch(actions.setTaggedItem(null));
    } else if (taggedItem) {
      if (recentTagsState && !isTextareaFocused() && globalSearchDiv === defaultGlobalSearchDiv) {
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const selectedNode = range?.commonAncestorContainer;
        let offset = 0;
        if (range) {
          offset = range.endOffset - range.startOffset;
        }
        if (isSafari && selection && offset
          && selectedNode?.nodeType === Node.TEXT_NODE) {
          const classes = range?.startContainer?.parentElement?.classList.value.split(' ') || '';
          const { textContent } = selectedNode;
          const span = document.createElement('span');
          for (let i = 0; i < classes.length; i += 1) {
            span.classList.add(classes[i]);
          }
          span.setAttribute('contenteditable', 'false');
          span.textContent = textContent;
          setValueHtml(valueHtml.replace(span.outerHTML, `<span key="${taggedItem.type}" data-color="${taggedItem.color ?? ''}" class="prompt-input-style c-pointer" id="%${taggedItem.id}">@${taggedItem?.name ?? ''}</span>`));
        } else {
          document.execCommand('insertHtml', false, `<span key="${taggedItem.type}" data-color="${taggedItem.color ?? ''}" class="prompt-input-style c-pointer" id="%${taggedItem.id}">@${taggedItem?.name ?? ''}</span>`);
        }
        setRecentTagFocus(true);
        setAppendRecentTagId(taggedItem.id);
        setRecentTagState(false);
      } else {
        if (taggedItem?.personType !== 'LIVESEARCH') {
          dispatch(actions.changeOrderRecentTags([taggedItem]));
        }
        setQuery('');
        const searchBarInput = document.getElementById('global-search-input-div');
        let index = -1;
        index = valueHtml.indexOf(globalSearchDiv);
        if (index === -1) {
          index = valueHtml.indexOf(prevGlobalSearchDiv || globalSearchDiv);
          if (index === -1) {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = valueHtml;
            const inputElement = tempElement.querySelector('#global-search-input-div');
            index = valueHtml.indexOf(inputElement?.outerHTML || globalSearchDiv);
          }
        }

        searchBarInput?.remove();
        let newVal;
        if (valueHtml.includes(globalSearchDivWithBR)) {
          newVal = valueHtml.replace(globalSearchDivWithBR, '');
        } else if (valueHtml.includes(globalSearchDiv)) {
          newVal = valueHtml.replace(globalSearchDiv, '');
        } else if (valueHtml.includes(prevGlobalSearchDiv || globalSearchDiv)) {
          newVal = valueHtml.replace(prevGlobalSearchDiv || globalSearchDiv, '');
        } else {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = valueHtml;
          const inputElement = tempElement.querySelector('#global-search-input-div');
          if (valueHtml.includes(`${inputElement?.outerHTML || ''}<br>`)) {
            newVal = valueHtml.replace(`${inputElement?.outerHTML || ''}<br>`, '');
          } else {
            newVal = valueHtml.replace(inputElement?.outerHTML || '', '');
          }
        }
        const first = newVal.slice(0, index);
        const second = newVal.slice(index);
        const tag = `<span key="${taggedItem.type}" data-color="${taggedItem.color ?? ''}" class="prompt-input-style c-pointer ${getActiveElement()}" id="${taggedItem.id}" contenteditable="false">@${taggedItem?.name ?? ''}</span>`;
        setValueHtml(first.concat(tag).concat(second || ''));
        const historyObj: HistoryItem = {
          text: first.concat(tag).concat(second || ''),
          placeholder: placeholderToReplace,
          allTags: taggedList,
          uniqueTags: uniqueTagList,
        };
        pushToHistory(historyObj);
        setTagStartIndex(-1);
        setSearchAdded(false);
        if (!isPreAddTag) {
          const regex = new RegExp(`id="${taggedItem.id}"`, 'g');
          const matches = first.match(regex);
          setSpanIdToFocus(matches?.length ?? 0);
          setSpanId(taggedItem.id);
        } else {
          setIsPreAddTag(false);
        }
        dispatch(actions.setPlaceholderToReplace(''));
        setRecentTagState(false);
      }
      dispatch(actions.setTaggedItem(null));
    }
  }, [taggedItem]);

  useEffect(() => {
    if (spanId) {
      const spanNodes = document.getElementById('custom-text-editable')?.getElementsByClassName('prompt-input-style');
      let spanNode: Element | null = null;
      if (recentTagFocus) {
        const filteredSpans = Array.from(spanNodes ?? [])
          .filter((span) => span.id === `%${spanId}`);
        [spanNode] = filteredSpans;
        const historyObj: HistoryItem = {
          text: valueHtml.replace(`id="%${spanId}"`, `id="${spanId}"`),
          placeholder: placeholderToReplace,
          allTags: taggedList,
          uniqueTags: uniqueTagList,
        };
        setValueHtml(valueHtml.replace(`id="%${spanId}"`, `id="${spanId}"`));
        pushToHistory(historyObj);
        if (spanNode) {
          spanNode.id = spanId.toString();
        }
      } else {
        const filteredSpans = Array.from(spanNodes ?? [])
          .filter((span) => span.id === spanId.toString());
        spanNode = filteredSpans[spanIdToFocus];
      }
      if (spanNode && spanNode.nextSibling) {
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStart(spanNode.nextSibling, range.startOffset);
        range.collapse(true);
        selection?.removeAllRanges();
        selection?.addRange(range);
        contentEditableRef.current?.focus();
      }
      setSpanId(0);
      setSpanIdToFocus(0);
      setRecentTagFocus(false);
    }
  }, [spanId]);

  const setRange = () => {
    const searchNode = document.getElementById('global-search-input-div');
    if (searchNode) {
      const range = document.createRange();
      const selection = window.getSelection();
      range.setStartAfter(searchNode);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
      contentEditableRef.current?.focus();
    }
  };

  useEffect(() => {
    if (pressBackspace) {
      setRange();
      setPressBackspace(false);
    }
  }, [pressBackspace]);

  function addTagsFromValue(html: string) {
    const div = document.createElement('div');
    div.innerHTML = html;
    const spanTags = div.querySelectorAll('span');
    spanTags.forEach((span) => {
      const key = span.getAttribute('key');
      const tagId = span.getAttribute('id');
      const name = span.innerText.replace(/@/g, '');
      if (tagId) {
        dispatch(actions.pushTagItem({
          id: parseInt(tagId || '0', 10),
          type: key,
          color: span.dataset.color,
          image: span.dataset.image,
          name,
        } as TagItem));
        dispatch(actions.setUniqueTaggedItem({
          id: parseInt(tagId || '0', 10),
          type: key,
          color: span.dataset.color,
          image: span.dataset.image,
          name,
        } as TagItem));
      }
    });
  }

  useEffect(() => {
    const handleShortcutKeys = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp' && valueWithoutHtml.trim().length === 0 && chatHistory[chatHistory.length - 1]?.prompt) {
        setArrowUpPress(true);
        dispatch(actions.resetTagItemsList());
        dispatch(actions.setPlaceholderToReplace(''));
        setValueHtml(chatHistory[chatHistory.length - 1]?.prompt);
        addTagsFromValue(chatHistory[chatHistory.length - 1]?.prompt);
        dispatch(UiAction.isGilroyTextHeightIncrease(true));
      }
    };
    window.addEventListener('keyup', handleShortcutKeys);
    return () => {
      window.removeEventListener('keyup', handleShortcutKeys);
    };
  }, [valueWithoutHtml]);

  useEffect(() => {
    const replace = valueHtml.replaceAll(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ').replace(/</g, '<').replace(/>/g, '>');
    setValueWithoutHtml(replace.replace(/&/g, '&'));
  }, [valueHtml]);

  useEffect(() => {
    let promptEmpty = false;
    if (!chatLoading && !isStopGenerating) {
      setValueHtml('');
      setValueWithoutHtml('');
      promptEmpty = true;
    } else if (!chatLoading && valueHtml && isStopGenerating) {
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
      setValueHtml(`${valueHtml} `);
      addTagsFromValue(valueHtml);
      pushToHistory({
        text: valueHtml,
        allTags: taggedList,
        uniqueTags: uniqueTagList,
        placeholder: placeholderToReplace,
      });
    }
    if (!chatLoading && (prevChatLoading || promptInProgress) && promptEmpty && pageType() !== 'document') {
      if (tagItem?.id) {
        setIsPreAddTag(true);
        dispatch(actions.setTaggedItem(tagItem));
        dispatch(actions.setUniqueTaggedItem(tagItem));
      }
      promptEmpty = false;
    }
    if (!chatLoading) {
      dispatch(actions.setPromptInProgress(''));
    }
  }, [chatLoading]);

  useEffect(() => {
    if (idToReplace?.executiveId) {
      if (valueHtml.includes(`data-color="" class="prompt-input-style c-pointer" id="${idToReplace.livesearchId}"`)) {
        setValueHtml(valueHtml.replace(`data-color="" class="prompt-input-style c-pointer" id="${idToReplace.livesearchId}"`, `data-color="${idToReplace.color}" class="prompt-input-style c-pointer" id="${idToReplace.executiveId}"`));
      } else if (valueHtml.includes(`id="${idToReplace.livesearchId}"`)) {
        setValueHtml(valueHtml.replace(`id="${idToReplace.livesearchId}"`, `id="${idToReplace.executiveId}"`));
      }
      setIdReplaced(true);
      setIsFocus(true);
      dispatch(actions.resetIdToReplacae());
    }
  }, [idToReplace]);

  useEffect(() => {
    if (searchRemove) {
      const node = getCurrentNode();
      node.focus();
      setSearchRemove(false);
    }
  }, [searchRemove]);
  const onLimitPopupClose = () => {
    dispatch(actions.setLimitPopupVisible(false));
    setEscapePress(true);
    if (tagStartIndex > -1) {
      setTagStartIndex(-1);
    }
  };
  const closeLimitPopUp = () => {
    setEscapePress(true);
    dispatch(actions.setLimitPopupVisible(false));
    setPopUpClose(false);
  };

  const onLimitPopupClick = () => {
    dispatch(UiAction.isGilroyTextHeightIncrease(true));
  };

  useEffect(() => {
    if (selectedConvoId && (prevSelectedConvoId || prevSelectedConvoId === 0)
      && !((prevSelectedConvoId === 0 || prevSelectedConvoId === -1)
        && chatHistory?.length === 1)) {
      dispatch(actions.resetTagging());
      setValueHtml('');
      setValueWithoutHtml(' ');
    }
  }, [selectedConvoId]);

  useEffect(() => {
    if (showSearchPopup) {
      setSearchPopupAppear(true);
      const searchBar = document.getElementById('global-search-input') as HTMLInputElement;
      if (searchBar) {
        searchBar.focus();
      }
    } else {
      setActivePopoverTab(1);
    }
  }, [showSearchPopup]);

  const getActiveTab = (): number => {
    switch (true) {
      case clickedElement?.classList?.contains('personAppend') || clickedElement?.classList?.contains('person-append'):
        return 1;
      case clickedElement?.classList?.contains('companyAppend') || clickedElement?.classList?.contains('company-append'):
        return 2;
      case clickedElement?.classList?.contains('groupAppend') || clickedElement?.classList?.contains('group-append'):
        return 3;
      case clickedElement?.classList?.contains('documentAppend') || clickedElement?.classList?.contains('document-append'):
        return 4;
      default:
        return 1;
    }
  };

  const handleClickInsideDiv = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!chatLoading && !isEditChatFlag) {
      dispatch(UiAction.isGilroyTextHeightIncrease(true));
    }
    const element = e.target as HTMLElement;
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    if (element && element.nodeName === 'SPAN' && (element.classList.contains('personAppend') || element.classList.contains('groupAppend') || element.classList.contains('companyAppend') || element.classList.contains('documentAppend') || element.id)) {
      setClickedElement(element);
      if (element.id) {
        setBypassCaret(true);
        const isIdExists = taggedList
          .reduce((acc, curr) => (curr.id === Number(element.id) ? acc + 1 : acc), 0);
        if (isIdExists <= 1) {
          dispatch(actions.removeUniqueTaggedItem(Number(element.id)));
        }
        let count = 0;
        let currentElement = element;
        while (currentElement?.previousElementSibling) {
          if (currentElement?.previousElementSibling?.id === element.id || (currentElement.previousElementSibling.id === 'global-search-input-div' && placeholderToReplace.includes(`id="${element.id}"`))) {
            count += 1;
          }
          currentElement = currentElement?.previousElementSibling as HTMLElement;
        }
        setClickedElementIndex(count);
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_tag_item_clicked', 0, {
            host,
          }),
        ));
      } else if (!element.id) {
        let count = 0;
        let currentElement = element;
        while (currentElement?.previousElementSibling) {
          if (currentElement?.previousElementSibling?.outerHTML === element.outerHTML || (currentElement.previousElementSibling.id === 'global-search-input-div' && placeholderToReplace.includes(element.outerHTML))) {
            count += 1;
          }
          currentElement = currentElement?.previousElementSibling as HTMLElement;
        }
        setClickedElementIndex(count);
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_entityPill_clicked', 0, {
            host,
          }),
        ));
      }
    } else if (element && element.nodeName === 'SPAN' && ((element.classList.length === 1 || (element.classList.length === 2 && element.classList[1].includes('execute_prompt'))) && !element.id)) {
      const r = document.createRange();
      const s = window.getSelection();
      if (isSafari) {
        r?.selectNodeContents(element);
      } else {
        r?.selectNode(element);
      }
      s?.removeAllRanges();
      s?.addRange(r);
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_placeHolder_clicked', 0, {
          host,
        }),
      ));
    } else if (range && range.startContainer?.parentElement?.nodeName === 'SPAN' && ((range.startContainer?.parentElement?.classList.length === 1 || (range.startContainer?.parentElement.classList.length === 2 && range.startContainer?.parentElement.classList[1].includes('execute_prompt'))) && !range.startContainer?.parentElement?.id)) {
      const r = document.createRange();
      r?.selectNode(range.startContainer?.parentElement);
      const s = window.getSelection();
      s?.removeAllRanges();
      s?.addRange(r);
    } else if (range && range?.startContainer?.parentElement?.nodeName === 'SPAN' && ((range?.endContainer?.parentElement?.nodeName === 'SPAN' && range?.startContainer?.parentElement?.classList.contains('c-pointer') && range.startContainer?.parentElement?.textContent?.length !== (range.endOffset - range.startOffset)) || (range?.startContainer?.parentElement?.classList?.contains('groupAppend')) || (range?.startContainer?.parentElement?.classList?.contains('personAppend')) || (range?.startContainer?.parentElement?.classList?.contains('companyAppend')) || (range?.startContainer?.parentElement?.classList?.contains('documentAppend')))) {
      setClickedElement(range.startContainer.parentElement);
    }
  };
  useEffect(() => {
    if (clickedElement) {
      const element = clickedElement;
      const span = extractSpanTagFromString(placeholderToReplace);
      setActivePopoverTab(getActiveTab());
      if (valueHtml.includes(globalSearchDiv) && placeholderToReplace) {
        if (valueHtml.includes(globalSearchDivWithBR)) {
          setValueHtml(valueHtml.replace(globalSearchDivWithBR, placeholderToReplace));
        } else {
          setValueHtml(valueHtml.replace(globalSearchDiv, placeholderToReplace));
        }
        if (span?.id) {
          dispatch(actions.pushTagItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
          dispatch(actions.setUniqueTaggedItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
          dispatch(actions.setPlaceholderToReplace(''));
        }
      } else if (valueHtml.includes(globalSearchDiv)) {
        if (valueHtml.includes(globalSearchDivWithBR)) {
          setValueHtml(valueHtml.replace(globalSearchDivWithBR, ''));
        } else {
          setValueHtml(valueHtml.replace(globalSearchDiv, ''));
        }
      }

      if ((taggedList.length >= TAG_LIMIT && !taggedList.some((obj) => obj.id === parseInt(element.id || '0', 10))) || (taggedList.length === TAG_LIMIT - 1 && span?.id && !taggedList.some((obj) => obj.id === parseInt(span?.id || '0', 10)) && !element?.id)) {
        dispatch(actions.setLimitPopupVisible(true));
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_tagLimitReached', startTime, {
            host,
          }),
        ));
        contentEditableRef.current?.blur();
      } else {
        dispatch(actions.setPlaceholderToReplace(element?.outerHTML?.toString() ?? ''));
        setPlaceholderChange(true);
        setIsTextFieldUpdated(true);
      }
      if (searchbarValue) {
        setSearchbarValue('');
      }
      setClickedElement(null);
    }
  }, [clickedElement]);

  const findNthOccurrence = (str: string, substr: string, nth: number) => {
    let index = -1;
    let n = nth;
    for (let i = 0; i < str.length; i += 1) {
      index = str.indexOf(substr, index + 1);
      if (index === -1) {
        break;
      }
      n -= 1;
      if (n === 0) {
        break;
      }
    }
    return index;
  };

  useEffect(() => {
    if (placeholderToReplace && placeholderChange) {
      const span = extractSpanTagFromString(placeholderToReplace);
      const isIdExists = taggedList
        .reduce((acc, curr) => (curr.id === parseInt(span?.id || '0', 10) ? acc + 1 : acc), 0);
      if (isIdExists <= 1) {
        dispatch(actions.removeUniqueTaggedItem(parseInt(span?.id || '0', 10)));
      }
      dispatch(actions.removeTagItem(parseInt(span?.id || '0', 10)));
      if (valueHtml.includes(placeholderToReplace)) {
        if (valueHtml.endsWith(placeholderToReplace)) {
          const index = findNthOccurrence(valueHtml, placeholderToReplace, clickedElementIndex + 1);
          const part1 = valueHtml.substring(0, index);
          const part2 = valueHtml.substring(index);
          const part2Temp = part2.replace(placeholderToReplace, '');
          if (part2Temp.includes(placeholderToReplace)) {
            setValueHtml(part1 + part2.replace(placeholderToReplace, globalSearchDiv));
          } else {
            setValueHtml(part1 + part2.replace(placeholderToReplace, globalSearchDivWithBR));
          }
          setClickedElementIndex(0);
        } else {
          const index = findNthOccurrence(valueHtml, placeholderToReplace, clickedElementIndex + 1);
          const part1 = valueHtml.substring(0, index);
          const part2 = valueHtml.substring(index);
          setValueHtml(part1 + part2.replace(placeholderToReplace, globalSearchDiv));

          setClickedElementIndex(0);
        }
      } else if (valueHtml.includes(placeholderToReplace.replaceAll('&', '&'))) {
        if (valueHtml.endsWith(placeholderToReplace.replaceAll('&', '&'))) {
          setValueHtml(valueHtml.replace(placeholderToReplace.replaceAll('&', '&'), globalSearchDivWithBR));
        } else {
          setValueHtml(valueHtml.replace(placeholderToReplace.replaceAll('&', '&'), globalSearchDiv));
        }
      }
      setSearchAdded(true);
      if ((span?.classList?.length === 2 || (span?.classList?.length === 3 && span?.classList?.toString()?.includes('-append'))) && span.classList.contains('c-pointer')) {
        setSearchbarValue(span.textContent?.replace('@', '').replace(/\.[^.]+$/, '') ?? '');
      }
      setPlaceholderChange(false);
    }
  }, [placeholderToReplace, placeholderChange]);

  useEffect(() => {
    if (escapePress) {
      if (valueHtml.includes(globalSearchDiv) && placeholderToReplace) {
        const span = extractSpanTagFromString(placeholderToReplace);
        if (valueHtml.includes(globalSearchDivWithBR)) {
          setValueHtml(valueHtml.replace(globalSearchDivWithBR, placeholderToReplace));
        } else {
          setValueHtml(valueHtml.replace(globalSearchDiv, placeholderToReplace));
        }
        if (span?.id) {
          dispatch(actions.setUniqueTaggedItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
          dispatch(actions.pushTagItem({
            id: parseInt(span.id || '0', 10),
            type: span.getAttribute('key'),
            color: span.dataset.color,
            image: span.dataset.image,
            name: span.innerText.replace(/@/g, ''),
          } as TagItem));
        }
      } else if (valueHtml.includes(globalSearchDiv)) {
        if (valueHtml.includes(globalSearchDivWithBR)) {
          setValueHtml(valueHtml.replace(globalSearchDivWithBR, ''));
        } else {
          setValueHtml(valueHtml.replace(globalSearchDiv, ''));
        }
      }
      setSearchbarValue('');
      setEscapePress(false);
      onSearchPopupCross();
      dispatch(actions.setPlaceholderToReplace(''));
      contentEditableRef?.current?.focus();
    }
  }, [escapePress]);

  useEffect(() => {
    dispatch(actions.getRecentTags());
  }, []);

  const setCaretToStart = (ref: RefObject<HTMLDivElement>) => {
    const element = ref.current;
    if (element) {
      const range = document.createRange();
      const selection = window.getSelection();
      if (selection) {
        range.setStart(element, 0);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
        element.focus();
      }
    }
  }

  const setCaretToEnd = (ref: RefObject<HTMLDivElement>) => {
    const element = ref.current;
    if (element) {
      const range = document.createRange();
      const selection = window.getSelection();
      if (selection) {
        range.selectNodeContents(element);
        range.collapse(false);
        selection.removeAllRanges();
        selection.addRange(range);
        element.focus();
      }
    }
  }

  useEffect(() => {
    if (isHeightTextArea) {
      setTogglleCaret(true);
      setTimeout(() => {
        setTogglleCaret(false);
        if (valueHtml.includes(globalSearchDiv) || bypassCaret) {
          searchBarInputById?.focus();
          setBypassCaret(false);
        }
        else {
          if (taggedList.length === 1 && (valueHtml.startsWith('<span key=') || valueHtml.startsWith('<br>')) && (valueHtml.endsWith('</span>') || valueHtml.endsWith('<br>'))) {
            setCaretToStart(contentEditableRef)
          }
          else {
            setCaretToEnd(contentEditableRef);
          }
        }
      });
    }
  }, [isHeightTextArea, isNewChat]);

  useEffect(() => () => {
    dispatch(actions.resetTagging());
  }, []);

  useEffect(() => {
    const setPopoverWidth = () => {
      if (customTextAreaRef.current) {
        const popover = customTextAreaRef.current.clientWidth;
        const popoverHeight = document && document.getElementById('chatbot-v3-body-height')?.clientHeight;
        const popoverHeightV2 = document && document.getElementById('body-content-height-popover')?.clientHeight;
        const popoverContent: HTMLElement | null = document.querySelector('.ant-popover-inner-content');
        if (popoverContent) {
          popoverContent?.style.setProperty('width', `${popover}px`);
        }
        if (popoverHeight && isGilroyV3) {
          popoverContent?.style.setProperty('height', `${popoverHeight - 48}px`);
        }
        if (popoverHeight && isGilroyV3 && isRetina125) {
          popoverContent?.style.setProperty('height', `${popoverHeight - 40}px`);
        }
        if (popoverHeight && isGilroyV3 && isTab) {
          popoverContent?.style.setProperty('height', `${popoverHeight / 2}px`);
        }
        if (popoverHeightV2 && !isGilroyV3) {
          popoverContent?.style.setProperty('height', `${popoverHeightV2 - 0}px`);
        }
      }
    };
    setPopoverWidth();
    window.addEventListener('resize', setPopoverWidth);
    return () => {
      window.removeEventListener('resize', setPopoverWidth);
    };
  }, [searchEnterPressed]);

  const handleContextMenu = (ev: MouseEvent) => {
    ev.preventDefault();
    const rect = (ev.target as HTMLElement).getBoundingClientRect();
    const posX = ev.clientX - rect.left;
    const menuWidth = menuRef.current?.clientWidth || 0;
    const divWidth = contentEditableRef.current?.clientWidth || 0;
    setButtonPosition({
      x: posX + menuWidth >= divWidth ? divWidth - menuWidth : posX,
      y: ev.clientY - rect.top + 15,
    });
    setShowMenuButton(true);
    navigator.clipboard.readText().then((t) => {
      setPasteDisable(t.trim().length === 0);
    }).catch(() => {
      setPasteDisable(!isSafari);
    });
  };

  useEffect(() => {
    getCurrentNode()?.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('click', () => setShowMenuButton(false));
    document.addEventListener('scroll', () => setShowMenuButton(false));
    return () => {
      getCurrentNode()?.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const handleTextSelection = () => {
    const selectedText = window.getSelection()?.toString() || '';
    setIsTextSelected(selectedText.length > 0);
  };

  const handleOutsideClick = (e: MouseEvent) => {
    const el = e.target as HTMLElement;
    const parentEl = el.parentElement as HTMLElement;
    if (!drawerPreviewOpen && !el.classList.contains('ja-contextmenu') && !el.classList.contains('ja-panel') && !parentEl.classList.contains('ja-contextmenu') && !el.classList.contains('menu-item-label') && !parentEl.classList.contains('menu-item-label')) {
      dispatch(UiAction.isGilroyTextHeightIncrease(false));
      dispatch(actions.setIsNewChat(false));
      onSearchPopupCross();
    }
  };

  const onCopyButtonCLick = (e: React.MouseEvent) => {
    e.preventDefault();

    const s = window.getSelection()?.toString();
    if (s) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_copy_content', startTime, {
          host,
        }),
      ));
      setBeforeCutText(getCurrentNode().innerHTML);
    }
    document.execCommand('copy');
    setShowMenuButton(false);
  };
  const onCutButtonCLick = (e: React.MouseEvent) => {
    e.preventDefault();
    const s = window.getSelection()?.toString();
    if (s) {
      dispatch(flurryActions.callFlurryEvent(
        valueToObject('xcgpt_cut_content', startTime, {
          host,
        }),
      ));
      setBeforeCutText(getCurrentNode().innerHTML);
    }
    document.execCommand('cut');
    setShowMenuButton(false);
  };

  const onPasteButtonCLick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigator.clipboard.readText()
      .then((text) => {
        document.execCommand('insertText', false, text);
        dispatch(flurryActions.callFlurryEvent(
          valueToObject('xcgpt_paste_content', startTime, {
            host,
          }),
        ));
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
    setShowMenuButton(false);
  };

  const onUndoButtonCLick = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsUndo(true);
    setShowMenuButton(false);
  };
  useEffect(() => {
    const flag = isHeightTextArea && !showSearchPopup && recentTags && recentTags.length > 0
      && (!spotlightLoading || !window.location.pathname.includes('feed/'));
    const node = document.querySelector('.body-content');
    return flag ? node?.classList.add('recentTags') : node?.classList.remove('recentTags');
  }, [isHeightTextArea && !showSearchPopup && recentTags && recentTags.length > 0
    && (!spotlightLoading || !window.location.pathname.includes('feed/'))]);

  return (
    <OutsideClickHandler
      onOutsideClick={handleOutsideClick}
    >
      <StyledWrapper
        onClick={onSearchPopupCross}
        className={classNames('custom-text-area-gilroy', {
          changeHeight: isHeightTextArea,
          notHtmlCase: valueHtml !== '' && !isHeightTextArea,
          hidePlaceholder: valueHtml.length > 0 && !isHeightTextArea && !chatLoading,
          isSearchBarSmall: !isHeightTextArea && valueHtml.includes(globalSearchDiv),
        })}
        ref={customTextAreaRef}
        id="custom-text-area-gilroy-search"
      >

        {isHeightTextArea && !showSearchPopup && recentTags && recentTags.length > 0
          && (!spotlightLoading || !window.location.pathname.includes('feed/'))
          ? (
            <RecentTags
              recentTags={recentTags}
              setRecentTagState={setRecentTagState}
              setPopUpClose={setPopUpClose}
            />
          ) : null}

        <SearchTaggingPopover
          activeTab={activePopoverTab}
          openPopover={showSearchPopup}
          onCrossClick={onSearchPopupCross}
        >

          <ContentEditable
            id="custom-text-editable"
            className={classNames(`textarea-input-gilroy ${translateSkipAreas}`, { 'gilroy-caret-style': toggleCaret })}
            onChange={onChangeText}
            onPaste={handlePasteInGpt}
            onKeyDown={handleKeyDown}
            onClick={handleClickInsideDiv}
            html={chatLoading ? '' : valueHtml}
            suppressContentEditableWarning
            spellCheck="false"
            draggable={false}
            placeholder=""
            innerRef={contentEditableRef}
            disabled={chatLoading || isEditChatFlag}
            onMouseUp={handleTextSelection}
          />
          <ContextMenuWrapper className="context-menu-panel" id="ctx-wrapper" ref={menuRef} x={`${buttonPosition.x}px`} y={`${buttonPosition.y}px`}>
            <button
              className={classNames('context-menu-button', { disabled: copyDisable })}
              disabled={copyDisable}
              hidden={!showMenuButton}
              type="button"
              onMouseDown={onCopyButtonCLick}
            >
              Copy
            </button>
            <button
              className={classNames('context-menu-button', { disabled: cutDisable })}
              disabled={cutDisable}
              hidden={!showMenuButton}
              type="button"
              onMouseDown={onCutButtonCLick}
            >
              Cut
            </button>
            <button
              className={classNames('context-menu-button', { disabled: pasteDisable })}
              disabled={pasteDisable}
              hidden={!showMenuButton}
              type="button"
              onMouseDown={onPasteButtonCLick}
            >
              Paste
            </button>
            <button
              className={classNames('context-menu-button', { disabled: undoDisable })}
              disabled={undoDisable}
              hidden={!showMenuButton}
              type="button"
              onMouseDown={onUndoButtonCLick}
            >
              Undo
            </button>
          </ContextMenuWrapper>
        </SearchTaggingPopover>
        {!valueHtml.trim().length || valueHtml.trim() === '<br>' || chatLoading ? (
          <span className="placeholder-content-editable">
            Use
            <span className="bold-text">“@”</span>
            to tag people, companies, buyer groups and documents
          </span>
        ) : null}
        {isHeightTextArea && (showDisclaimer || showDisclaimerGlobal) && (disclaimerText || disclaimerTextGlobal) && !isTab ? (
          <div className={classNames('samsung-disclaimer-div', { gilroyV3Disclaimer: isGilroyV3, nutanixUser: showDisclaimerGlobal })}>
            <InfoIconTwoRed className="InfoIconTwoRed" fillColor="#CF1322" />
            <span className={`disclaimer-text ${translateSkipAreas} ${isGilroyV3 ? 'text-truncate-three-line' : 'text-truncate-two-line'} `}>
              {HTMLReactParser(showDisclaimer ? disclaimerText.join('<br />') : disclaimerTextGlobal.join('<br />'))}
            </span>
          </div>
        ) : null}
        <div
          role="button"
          tabIndex={0}
          onClick={handleGenerateChat}
          onKeyPress={handleGenerateChat}
          className={classNames('generate-btn-gilroy', { disabled: generateDisable })}
        >
          {personCardLoading || tagSetupLoading ? (
            <Spin />
          ) : (
            <>
              <WhiteBotIcon />
              <span>Generate</span>
            </>
          )}
        </div>
      </StyledWrapper>
      <GilroyTaggingLimitPopup
        isVisible={showLimitPopup}
        onClose={popUpClose ? closeLimitPopUp : onLimitPopupClose}
        onClick={onLimitPopupClick}
      />
    </OutsideClickHandler>
  );
};
CustomTextAreaGilroy.defaultProps = defaultProps;
export default CustomTextAreaGilroy;