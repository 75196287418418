/* eslint-disable @typescript-eslint/no-unsafe-return */

import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'Store/InjectorsTypes';
import { initialState } from 'Slice/GlobalSearchContentSlice';

const selectDomain = (state: RootState) => state?.globalSearchContent || initialState;

export const selectLoading = createSelector([selectDomain], (state) => state.loading);
export const selectLoaded = createSelector([selectDomain], (state) => state.loaded);
export const selectApiCalled = createSelector([selectDomain], (state) => state.apiCalled);
export const selectErrorText = createSelector([selectDomain], (state) => state.errorText);
export const selectRecentSearches = createSelector([selectDomain], (state) => state.recentSearches);
export const selectMarketingPopup = createSelector([selectDomain], (state) => state.popupResponse);
export const selectExpiredPopup = createSelector([selectDomain], (state) => state.expiredPopup);
export const selectShowChatbot = createSelector([selectDomain], (state) => state.showChatbot);
export const selectShowGlobalGilroy = createSelector(
  [selectDomain],
  (state) => state.showGlobalGilroy,
);
export const selectTrendingSearches = createSelector([selectDomain], (
  state,
) => state.trendingSearches);
export const selectArticleCount = createSelector(
  [selectDomain],
  (state) => state.articleCount,
);
export const selectPeopleCount = createSelector(
  [selectDomain],
  (state) => state.peopleCount,
);
export const selectScreenName = createSelector(
  [selectDomain],
  (state) => state.screenName,
);
export const selectBelts = createSelector(
  [selectDomain],
  (state) => state.belt,
);
export const selectShowMappingPopup = createSelector(
  [selectDomain],
  (state) => state.showMappingPopup,
);
export const selectShowMappingTooltip = createSelector(
  [selectDomain],
  (state) => state.showMappingTooltip,
);

export const selectShowDocumentHub = createSelector(
  [selectDomain],
  (state) => state.showDcoumentHub,
);

export const selectShowUploadDocument = createSelector(
  [selectDomain],
  (state) => state.uploadDocument,
);

export const selectShowTranslationWidget = createSelector(
  [selectDomain],
  (state) => state.translationWidget,
);

export const selectUserCompanyId = createSelector(
  [selectDomain],
  (state) => state.companyId,
);

export const selectUserId = createSelector(
  [selectDomain],
  (state) => state.userId,
);

export const selectPersonId = createSelector(
  [selectDomain],
  (state) => state.personId,
);

export const selectDisclessPersonality = createSelector(
  [selectDomain],
  (state) => state.disclessPersonality,
);
export const selectGilroyDiscalimer = createSelector(
  [selectDomain],
  (state) => state.gilroyDisclaimer,
);

export const selectGilroyDiscalimerGlobal = createSelector(
  [selectDomain],
  (state) => state.gilroyDisclaimerGlobal,
);

export const selectComplianceAckCalling = createSelector(
  [selectDomain],
  (state) => state.isComplianceAckCall,
);

export const selectComplianceAckCallSuccess = createSelector(
  [selectDomain],
  (state) => state.isComplianceAckCallSuccess,
);

export const selectChangeLanguageLoaded = createSelector(
  [selectDomain],
  (state) => state.changeLanguageLoaded,
);

export const selectRestrictAppAccess = createSelector(
  [selectDomain],
  (state) => state.restrictAppAccess,
);

export const selectGilroyPopup = createSelector(
  [selectDomain],
  (state) => state.gilroyPopup,
);

export const selectUserPhone = createSelector(
  [selectDomain],
  (state) => state.phone,
);
